import React, { Component } from "react";

//child components
import DashboardBanner from "../DashboardBanner/DashboardBannerComponent";
import DashboardQuickPlay from "../DashboardQuickPlay/DashboardQuickPlayComponent";
import DashboardPopularHost from "../DashboardPopularHost/DashboardPopularHostComponent";
import DashboardFreeToPlayGames from "../DashboardFreeToPlayGames/DashboardFreeToPlayGamesComponent";
import DashboardNews from "../DashboardNews/DashboardNewsComponents";

//containers
import TournamentCardContainer from "../../Tournaments/TournamentCardContainer";

//images
import navArrowRightBlue from "../../../assets/images/svg/nav-arrow-right-blue.svg";
import adv2 from "../../../assets/images/adv/adv-2.png";
import adv3 from "../../../assets/images/adv/adv-1.png";
import adv2Nick from "../../../assets/images/adv/adv-nick-1.png";
import adv3Nick from "../../../assets/images/adv/adv-nick-1.png";
import { withTranslation } from "react-i18next";

class DashboardComponent extends Component {
  constructor(props) {
    super(props);

    this.loadChallenge = this.loadChallenge.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
  }

  handleRefresh() {
    this.loadChallenge();
  }

  loadChallenge() {
    const {
      fetchChallengesLiveIfNeeded,
      fetchChallengesQuickPlayIfNeeded,
      fetchChallengesUpcomingIfNeeded,
      fetchFreeToPlayIfNeeded,
      fetchFeatureChallengesIfNeeded,
      invalidateFreeToPlayIfNeeded,
      fetchNewsIfNeeded,
      invalidateChallengesLiveIfNeeded,
      fetchPopularBrandsIfNeeded,
      invalidateChallengesQuickPlayIfNeeded,
      invalidateChallengesUpcomingIfNeeded,
      invalidatePopularBrandsIfNeeded,
      invalidateNewsIfNeeded,
      invalidateFeatureChallengesIfNeeded
      //fetchChallengesPremiumIfNeeded,
      //invalidateChallengesPremiumIfNeeded
    } = this.props;

    invalidateChallengesLiveIfNeeded();
    fetchChallengesLiveIfNeeded({ brandId: "", page: 1 });

    invalidateChallengesQuickPlayIfNeeded();
    fetchChallengesQuickPlayIfNeeded({
      brandId: "",
      page: 1
    });

    //invalidateChallengesPremiumIfNeeded();
    //fetchChallengesPremiumIfNeeded({  page: 1 });

    invalidateChallengesUpcomingIfNeeded();
    fetchChallengesUpcomingIfNeeded({ brandId: "", page: 1 });

    invalidatePopularBrandsIfNeeded();
    fetchPopularBrandsIfNeeded({ page: 1 });

    invalidateFreeToPlayIfNeeded();
    fetchFreeToPlayIfNeeded({ page: 1 });

    invalidateNewsIfNeeded();
    fetchNewsIfNeeded({
      page: 1
    });

    invalidateFeatureChallengesIfNeeded();
    fetchFeatureChallengesIfNeeded({ page: "" });
  }

  componentDidMount() {
    this.loadChallenge();
    window.scrollTo(0, 0);
  }

  componentDidUpdate() {
    document.body.classList.remove("modal-open");
  }

  render() {
    const {
      challengesLiveList,
      challengesQuickPlayList,
      challengesUpcomingList,
      brandsPopularList,
      newsListAll,
      freeToPlayList,
      t,
      history
    } = this.props;
    return (
      <>
        {/*Banner*/}

        <DashboardBanner
          history={history}
          featureChallengesList={this.props.featureChallengesList}
          featureChallengesIsFetching={this.props.featureChallengesIsFetching}
          t={t}
        />

        {/*Live Tournaments*/}
        <section className="[ container-fluid ] live-tournament ">
          <div className="padder-main">
            <div className="row">
              <div className="container">
                <div className="row d-flex align-items-center mb-30 ">
                  <div className="col-8 d-flex justify-content-start">
                    <h2 className="bold">{t("liveTournaments")}</h2>
                  </div>
                  {challengesLiveList && challengesLiveList.length > 0 ? (
                    <div className="col text-right d-flex justify-content-end">
                      <a
                        href="challenges/live"
                        className="view-all d-flex align-items-center"
                      >
                        {t("viewAll")}
                        <span>
                          <img src={navArrowRightBlue} alt="" />
                        </span>
                      </a>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="row livetournament">
              {challengesLiveList && challengesLiveList.length > 0 ? (
                <TournamentCardContainer
                  // history={history}
                  // tagPart1="gtmJoinTournament-"
                  // tagPart2="Tournament"
                  list={
                    challengesLiveList
                      ? challengesLiveList.map((ele, inde) => {
                          return {
                            ...ele,
                            cardClass: "card popular-arcadex",
                            cardHeaderClass: "card-header light"
                          };
                        })
                      : []
                  }
                  showAd={
                    process.env.REACT_APP_PLATFORM !== "nickx" ? false : false
                  }
                  challengeType="Live"
                />
              ) : (
                <div className="col">
                  <h6 className="text-center">{t("noTournamentFound")}</h6>
                </div>
              )}
            </div>
            {/* {process.env.REACT_APP_PLATFORM !== "nickx" ? (
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 d-flex justify-content-center ">
                    <div
                      className="banner-advertising sm d-none d-lg-block mt-6"
                      id="adv-2"
                    >
                      <img
                        src={
                          process.env.REACT_APP_PLATFORM === "nickx"
                            ? adv2Nick
                            : adv2
                        }
                        alt=""
                        className="d-none d-lg-block"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )} */}
          </div>
        </section>

        {/*Quick Play*/}
        <section className="[ container-fluid ] quick-play section-border-top">
          <div className="padder-main">
            <div className="row">
              <div className="container">
                <div className="row d-flex align-items-center mb-30 ">
                  <div className="col-8 d-flex justify-content-start">
                    <h2 className="bold">{t("quickPlay")}</h2>
                  </div>
                  {challengesQuickPlayList &&
                  challengesQuickPlayList.length > 0 ? (
                    <div className="col text-right d-flex justify-content-end">
                      <a
                        href="challenges/quickplay"
                        className="view-all d-flex align-items-center"
                      >
                        {t("viewAll")}
                        <span>
                          <img src={navArrowRightBlue} alt="" />
                        </span>
                      </a>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="row livetournament">
              {challengesQuickPlayList && challengesQuickPlayList.length > 0 ? (
                <DashboardQuickPlay 
                  // history={history}
                  // tagPart1="gtmJoinQuickPlay-"
                  // tagPart2="QuickPlay" 
                  list={challengesQuickPlayList} 
                  /> 
              ) : (
                <div className="col">
                  <h6 className="text-center">{t("noTournamentFound")}</h6>
                </div>
              )}
            </div>
          </div>
        </section>

        {/*Upcoming Tournaments*/}
        <section className="[ container-fluid ] upcoming-tournament section-border-top">
          <div className="padder-main">
            <div className="row">
              <div className="container">
                <div className="row d-flex align-items-center mb-30 ">
                  <div className="col-8 d-flex justify-content-start">
                    <h2 className="bold">{t("upcomingTournaments")}</h2>
                  </div>
                  {challengesUpcomingList &&
                  challengesUpcomingList.length > 0 ? (
                    <div className="col text-right d-flex justify-content-end">
                      <a
                        href="challenges/upcoming"
                        className="view-all d-flex align-items-center"
                      >
                        {t("viewAll")}{" "}
                        <span>
                          <img src={navArrowRightBlue} alt="" />
                        </span>
                      </a>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="row livetournament">
              {challengesUpcomingList && challengesUpcomingList.length > 0 ? (
                <TournamentCardContainer
                  list={
                    challengesUpcomingList
                      ? challengesUpcomingList.map((ele, inde) => {
                          return {
                            ...ele,
                            cardClass: "card popular-arcadex",
                            cardHeaderClass: "card-header light"
                          };
                        })
                      : []
                  }
                  challengeType="Upcoming"
                />
              ) : (
                <div className="col">
                  <h6 className="text-center">{t("noTournamentFound")}</h6>
                </div>
              )}
            </div>
            {/* {process.env.REACT_APP_PLATFORM !== "nickx" ? (
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 d-flex justify-content-center">
                    <div
                      className="banner-advertising sm d-none d-lg-block mt-6 "
                      id="adv-3"
                    >
                      <img
                        src={
                          process.env.REACT_APP_PLATFORM === "nickx"
                            ? adv3Nick
                            : adv3
                        }
                        alt=""
                        className="d-none d-lg-block"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )} */}
          </div>
        </section>

        {/*Popular Hosts*/}
        <section className="[ container-fluid ] popular-host section-border-top">
          <div className="padder-main">
            <div className="row">
              <div className="container">
                <div className="row d-flex align-items-center mb-30 ">
                  <div className="col-8 d-flex justify-content-start">
                    <h2 className="bold">{t("popularHost")}</h2>
                  </div>
                  {brandsPopularList && brandsPopularList.length > 0 ? (
                    <div className="col text-right d-flex justify-content-end">
                      <a
                        href="hosts/popular"
                        className="view-all d-flex align-items-center"
                      >
                        {t("viewAll")}{" "}
                        <span>
                          <img src={navArrowRightBlue} alt="" />
                        </span>
                      </a>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="row livetournament">
              {brandsPopularList && brandsPopularList.length > 0 ? (
                <DashboardPopularHost list={brandsPopularList} />
              ) : (
                <div className="col">
                  <h6 className="text-center">{t("noHostsFound")}</h6>
                </div>
              )}
            </div>
          </div>
        </section>

        {/*Free to Play Games*/}
        <section className="[ container-fluid ] free-to-play-games section-border-top">
          <div className="padder-main">
            <div className="row">
              <div className="container">
                <div className="row d-flex align-items-center mb-30 ">
                  <div className="col-8 d-flex justify-content-start">
                    <h2 className="bold">{t("freeToPlayGame")}</h2>
                  </div>
                  {freeToPlayList && freeToPlayList.length > 0 ? (
                    <div className="col text-right d-flex justify-content-end">
                      <a
                        href={"freetoplay"}
                        className="view-all d-flex align-items-center"
                      >
                        {t("viewAll")}{" "}
                        <span>
                          <img src={navArrowRightBlue} alt="" />
                        </span>
                      </a>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="row livetournament">
              {freeToPlayList && freeToPlayList.length > 0 ? (
                <DashboardFreeToPlayGames list={freeToPlayList} />
              ) : (
                <div className="col">
                  <h6 className="text-center">{t("noGameFound")}</h6>
                </div>
              )}
            </div>
          </div>
        </section>

        {/*News*/}
        <section className="[ container-fluid ] news section-border-top">
          <div className="padder-main">
            <div className="row">
              <div className="container">
                <div className="row d-flex align-items-center mb-30 ">
                  <div className="col-8 d-flex justify-content-start">
                    <h2 className="bold">{t("navNews")}</h2>
                  </div>
                  {newsListAll && newsListAll.length > 0 ? (
                    <div className="col text-right d-flex justify-content-end">
                      <a
                        href={"news"}
                        className="view-all d-flex align-items-center"
                      >
                        {t("viewAll")}{" "}
                        <span>
                          <img src={navArrowRightBlue} alt="" />
                        </span>
                      </a>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="row livetournament">
              {newsListAll && newsListAll.length > 0 ? (
                <DashboardNews list={newsListAll} />
              ) : (
                <div className="col">
                  <h6 className="text-center">{t("noNewsFound")}</h6>
                </div>
              )}
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default withTranslation()(DashboardComponent);
