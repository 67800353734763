import React, { Component } from "react";
import SwiperContainer from "../Slider/SwiperContainer";
import GameCardComponent from "./GameCardComponent";
import totallyTurtles from "../../assets/images/totally-turtles.png";
import cabddyBubble from "../../assets/images/cabddy-bubble.png";
import highwayRiderExtreme from "../../assets/images/Highway-Rider-Extreme.png";
import monsterIslandAdventure from "../../assets/images/monster-island-adventures.png";
import { withTranslation } from "react-i18next";

class GameCardContainer extends Component {
  render() {
    const { t } = this.props;
    const params = {
      slidesPerView: "auto",
      containerClass:
        "swiper-container swiper-arcadexmobi swiper-container-horizontal",
      spaceBetween: 25,
      loop: false,
      centeredSlides: false,
      breakpoints: {
        1024: {
          spaceBetween: 10
        },
        768: {
          spaceBetween: 10
        },
        640: {
          spaceBetween: 20
        },
        320: {
          spaceBetween: 20,
          touchRatio: 1
        }
      }
    };
    const gameDataNickX = [
      {
        id: 1,
        gameCardName: t("spongebob"),
        cardClass: "card card-arcadexMobi",
        gameCardImage: monsterIslandAdventure
      },
      {
        id: 2,
        gameCardName: t("TMNT"),
        cardClass: "card card-arcadexMobi",
        gameCardImage: totallyTurtles
      }
    ];
    const gameData = [
        {
            id: 1,
            gameCardName: t("spongebob"),
            cardClass: "card card-arcadexMobi",
            gameCardImage: cabddyBubble
          },
          {
            id: 2,
            gameCardName: t("TMNT"),
            cardClass: "card card-arcadexMobi",
            gameCardImage: highwayRiderExtreme
          }
    ];

    return (
      <SwiperContainer {...params}>
        {process.env.REACT_APP_PLATFORM === "nickx"
          ? gameDataNickX.map((game, index) => (
              <GameCardComponent {...game} key={index} />
            ))
          : gameData.map((game, index) => (
              <GameCardComponent {...game} key={index} />
            ))}
      </SwiperContainer>
    );
  }
}

export default withTranslation()(GameCardContainer);
