import React, { PureComponent } from "react";
import "./Leaderboard.scss";
import { connect } from "react-redux";
import Rank_1 from "../../assets/images/svg/rank-no-1.svg";
import Rank_2 from "../../assets/images/svg/rank-no-2.svg";
import Rank_3 from "../../assets/images/svg/rank-no-3.svg";
import MoreIcon from "../../assets/images/svg/more-select-premium.svg";
import { ordinalSuffix, findWithAttr, leftJoin } from "../../utils/misc";
import avatar3x from "../../assets/images/avatar-demo.png";
import NiceDropdownComponent from "../../components/NiceDropdown/NiceDropdownComponent";
import "../../components/NiceDropdown/NiceDropdownComponent.scss";
import { friendsList } from "../../actions/friends";
import { followings } from "../../actions/profiles";
import { challengesLeaderboardsViewAll } from "../../actions/challenges";
import adv8 from "../../assets/images/adv/adv-1.png";
import adv8Nickx from "../../assets/images/adv/adv-nick-1.png";

class Leaderboard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showAll: false,
      isMore: true
    };
    this.getPlayersTable = this.getPlayersTable.bind(this);
  }

  componentDidMount() {
    // const {
    //   fetchChallengesLeaderboardsViewAllIfNeeded,
    //   invalidateAllChallengesLeaderboardsViewAll,
    //   data
    // } = this.props;
    // if (data.id) {
    //   invalidateAllChallengesLeaderboardsViewAll();
    //   fetchChallengesLeaderboardsViewAllIfNeeded({
    //     challengeId: data.id,
    //     page: 1
    //   });
    //   //fetchCategoryChallengesViewAllIfNeeded({ categoryId: id, page: 1 });
    // }
  }

  getNoPlayers() {
    return (
      <div>
        There’s no players active
        <br /> Leaderboard will be created when players join
      </div>
    );
  }

  addDefaultSrc(ev) {
    ev.target.src = avatar3x;
  }

  onShowAll(showAll) {
    this.setState({
      showAll: showAll,
      isMore: false
    });
  }

  getMoreIcon() {
    return (
      <div className="row d-flex justify-content-center mt-2">
        <div className="col-lg-12 text-center">
          <span
            onClick={e => {
              e.preventDefault();
              this.props.invalidateChallengesLeaderboardsViewAll();
              this.props.fetchChallengesLeaderboardsViewAllIfNeeded({
                challengeId: this.props.data.id,
                page: this.props.lpage
              });
            }}
          >
            {" "}
            <img src={MoreIcon} alt="" />{" "}
          </span>
        </div>
      </div>
    );
  }

  getLastRowForUserDetail(leaderboardData, userIndex) {
    let userData = leaderboardData;
    return (
      <div className="row d-flex justify-content-center scoreboard-header mt-4">
        <div className="col-lg-12 col-xl-9 text-center scoreboard-header-wrapper score-result">
          <div className="d-flex align-items-center justify-content-center">
            <ul className="list-unstyled w-100">
              <li className="scoreboard-row py-2">
                <ul className="list-inline w-100 d-flex align-items-center justify-content-center">
                  <li className="list-inline-item d-flex align-items-center justify-content-center text-left">
                    <h6 className="bold text-white">{userData.rank}</h6>
                  </li>
                  <li className="list-inline-item flex-fill text-left ml-0 ml-lg-4 fixed-name">
                    <div className="d-flex align-items-center">
                      <img
                        src={
                          userData.profileAvatarUrl
                            ? userData.profileAvatarUrl
                            : avatar3x
                        }
                        className="profile-pic-56"
                        alt=""
                        onError={this.addDefaultSrc}
                      />
                      <p className="bold ml-3 text-truncate text-white player-name">
                        {userData.profileName}
                      </p>
                    </div>
                  </li>
                  <li className="list-inline-item flex-fill bold text-right mr-3">
                    <h6 className="bold text-white">{userData.points}</h6>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }

  getPlayersTable(leaderboard) {
    return leaderboard.map((player, index) => {
      const isInList = this.props.currentUserLeaderBoard
        ? this.props.currentUserLeaderBoard.rank === index + 1
        : false;

      // const isInList = this.props.currentUserLeaderBoard
      //   ? this.props.currentUserLeaderBoard.rank <
      //       this.props.leaderboard.length &&
      //     this.props.currentUserLeaderBoard.rank === index + 1
      //   : false;

      if (player.profileId !== this.props.userId || isInList) {
        let positionIcon = "";
        if (player.position === 1) positionIcon = Rank_1;

        if (player.position === 2) positionIcon = Rank_2;

        if (player.position === 3) positionIcon = Rank_3;

        if (!player.profileAvatarUrl) {
          player.profileAvatarUrl = avatar3x;
        }

        const { friendsList, followingsList, allOnlineUserList } = this.props;

        var friendsIds = [];
        if (friendsList && friendsList.length > 0) {
          friendsList.map((p, i) => friendsIds.push(p.id));
        }

        var followingsIds = [];
        if (followingsList && followingsList.length > 0) {
          followingsList.map((p, i) => followingsIds.push(p.id));
        }

        let positionText = index + 1;

        return (
          <li
            className={
              "scoreboard-row bb-1 py-2" + (isInList ? " user-highlighted-challenge" : "")
            }
            key={index}
          >
            <ul
              className={
                "list-inline w-100 d-flex align-items-center justify-content-center" +
                (isInList ? " user-PaddingUL-Challenge" : "")
              }
            >
              <li className="list-inline-item d-flex align-items-center justify-content-center text-left">
                {positionIcon ? (
                  <img src={positionIcon} alt="" className="rank" />
                ) : (
                  <h6 className="bold">{positionText}</h6>
                )}
              </li>
              <li className="list-inline-item flex-fill text-left ml-0 ml-lg-4 fixed-name">
                <div className="d-flex justify-content-center align-items-center">
                  <figure>
                    <img
                      src={player.profileAvatarUrl}
                      className="profile-pic-56"
                      alt=""
                      onError={this.addDefaultSrc}
                    />
                    {allOnlineUserList.includes(player.profileId) ? (
                      <figcaption className="online-56" />
                    ) : null}
                  </figure>
                  <p className="bold ml-3 text-truncate player-name">
                    {player.profileName}
                  </p>
                </div>
              </li>
              <li className="list-inline-item flex-fill text-right fixed-score">
                <span
                  className={
                    "h6 bold text-truncate " +
                    (!isInList
                      ? process.env.REACT_APP_PLATFORM === "nickx"
                        ? "text-orange"
                        : "text-secondary"
                      : "")
                  }
                >
                  {player.points}
                </span>
                {!isInList ? (
                  <NiceDropdownComponent
                    reporterId={this.props.userId}
                    reporteeId={player.profileId}
                    following={
                      followingsIds.indexOf(player.profileId) > -1
                        ? "true"
                        : "false"
                    }
                    profileId={player.profileId}
                    followerId={this.props.userId}
                    followeeId={player.profileId}
                    avatarUrl={player.profileAvatarUrl}
                    userName={player.profileName}
                    isFriend={friendsIds.indexOf(player.profileId) > -1}
                  />
                ) : (
                  <></>
                )}
              </li>
            </ul>
          </li>
        );
      }
      return null;
    });
  }

  getResultsList() {
    if (this.props.leaderboard && this.props.leaderboard.length === 0) {
      return this.getNoPlayers();
    }

    const userIndex = findWithAttr(
      this.props.leaderboard,
      "profileId",
      this.props.userData.id
    );

    let allChallengeData = this.props.data;
    // let leaderboardData = leftJoin(
    //   allChallengeData.leaderboard,
    //   allChallengeData.players.slice(0),
    //   "profile_id",
    //   "id",
    //   ["name", "id", "avatarUrl"]
    // );
    let leaderboardData = this.props.leaderboard.map((p, index) => ({
      ...p,
      position: index + 1
    }));

    let leaderboard = leaderboardData.slice(0);
    if (!this.state.showAll) {
      if (leaderboard.length <= 10) {
        // leaderboard is less or equal to ten users so show all
      } else if (leaderboard.length > 10 && userIndex < 10) {
        leaderboard = leaderboard.slice(0, 10);
      } else if (userIndex >= 10) {
        // user is after first 10
        leaderboard = leaderboard.slice(0, 9);
      } else if (userIndex === -1) {
        // user not in the list, show first 8 and last one
        leaderboard = leaderboard.slice(0, 9);
      }
    }

    let userHeaderData = {
      position: "-",
      points: "-",
      avatarUrl: this.props.userData.avatarUrl
    };

    if (userIndex >= 0) {
      userHeaderData = leaderboardData[userIndex];
    }
    const { t } = this.props;
    return (
      <div className="row d-flex justify-content-center">
        <div className="col-lg-12 text-center">
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-today"
              role="tabpanel"
              aria-labelledby="pills-today-tab"
            >
              <div className="row d-flex justify-content-center score-info">
                <div className="col-lg-12 col-xl-9 text-center score-info-wrapper">
                  <div className="row d-flex align-items-center justify-content-center mobile-panel">
                    <div className="col br-1 order-1 order-lg-1">
                      <p className="small semi-bold">
                        {t("challengePage.your")} {t("challengePage.rank")}
                      </p>
                      <h4
                        className={
                          "bold " +
                          (process.env.REACT_APP_PLATFORM === "nickx"
                            ? "text-orange"
                            : "text-secondary")
                        }
                        dangerouslySetInnerHTML={{
                          __html: this.props.currentUserLeaderBoard
                            ? this.props.currentUserLeaderBoard.rank
                            : "0"
                            ? ordinalSuffix(
                                this.props.currentUserLeaderBoard
                                  ? this.props.currentUserLeaderBoard.rank
                                  : "0",
                                true
                              )
                            : "-"
                        }}
                      />
                    </div>

                    <div className="col order-3 order-lg-2">
                      <p className="small semi-bold">
                        {t("challengePage.your")} {t("challengePage.score")}
                      </p>
                      <h4
                        className={
                          "bold " +
                          (process.env.REACT_APP_PLATFORM === "nickx"
                            ? "text-orange"
                            : "text-secondary")
                        }
                      >
                        {this.props.currentUserLeaderBoard
                          ? this.props.currentUserLeaderBoard.points
                          : "-"}
                      </h4>
                    </div>
                    <div className="col-auto profile-pic order-2 order-lg-3">
                      <figure>
                        <img
                          src={
                            userHeaderData.profileAvatarUrl
                              ? userHeaderData.profileAvatarUrl
                              : avatar3x
                          }
                          alt=""
                          className="profile-pic-96"
                          onError={this.addDefaultSrc}
                        />
                        <figcaption className="online-96" />
                      </figure>
                    </div>
                    <div className="col br-1 d-none d-lg-block order-lg-4">
                      <p className="small semi-bold">
                        {t("challengePage.gamePlayed")}
                      </p>
                      <h4
                        className={
                          "bold " +
                          (process.env.REACT_APP_PLATFORM === "nickx"
                            ? "text-orange"
                            : "text-secondary")
                        }
                      >
                        {this.props.gamesPlayed}
                      </h4>
                    </div>
                    <div className="col d-none d-lg-block order-lg-5">
                      <p className="small semi-bold d-none d-lg-block">
                        {t("challengePage.tokenEarn")}
                      </p>
                      <h4
                        className={
                          "bold " +
                          (process.env.REACT_APP_PLATFORM === "nickx"
                            ? "text-orange"
                            : "text-secondary")
                        }
                      >
                        {this.props.userData.points
                          ? this.props.userData.points
                          : 0}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  "row d-lg-flex justify-content-center scoreboard-header mt-5"
                }
              >
                <div
                  className={
                    "col-lg-12 col-xl-9 text-center scoreboard-header-wrapper " +
                    (process.env.REACT_APP_PLATFORM === "nickx" ? "nickx" : "")
                  }
                >
                  <div className="row d-flex align-items-center justify-content-center p-3">
                    <div className="col-auto text-left text-white small bold">
                      {t("challengePage.rank")}
                    </div>
                    <div className="col text-left text-white small bold">
                      {t("challengePage.player")}
                    </div>
                    <div className="col text-right text-white small bold">
                      {t("challengePage.scores")}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row d-flex justify-content-center scoreboard mt-2 mt-lg-1">
                <div
                  className={
                    "col-lg-12 col-xl-9 text-center scoreboard-wrapper " +
                    (process.env.REACT_APP_PLATFORM === "nickx" ? "nickx" : "")
                  }
                >
                  <div className="d-flex align-items-center justify-content-center">
                    <ul className="list-unstyled w-100 mt-2">
                      {this.getPlayersTable(this.props.leaderboard)}
                    </ul>
                  </div>
                </div>
              </div>
              {leaderboard.length > 0 && !this.props.isLastPage
                ? this.getMoreIcon()
                : null}

              {this.props.currentUserLeaderBoard &&
              this.props.currentUserLeaderBoard.rank >
                this.props.leaderboard.length
                ? this.getLastRowForUserDetail(
                    this.props.currentUserLeaderBoard
                  )
                : null}
            </div>
            <div
              className="tab-pane fade"
              id="pills-overall"
              role="tabpanel"
              aria-labelledby="pills-overall-tab"
            >
              OverAll
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <section className="container-fluid leader-board">
        <div className="row padder leaderboard">
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-12 text-center">
                <h2 className="mb-5-half bold">
                  {this.props.t("challengePage.leaderBoard")}
                </h2>
              </div>
            </div>

            {this.getResultsList()}

            {/* {process.env.REACT_APP_PLATFORM !== "nickx" ? (
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 d-flex justify-content-center ">
                    <div
                      className="banner-advertising sm d-none d-lg-block mt-6"
                      id="adv-3"
                    >
                      <img
                        src={
                          process.env.REACT_APP_PLATFORM === "nickx"
                            ? adv8Nickx
                            : adv8
                        }
                        alt=""
                        className="d-none d-lg-block"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )} */}
          </div>
        </div>
      </section>
    );
  }
}

const mapDispatchToProps = {
  //friends list
  fetchFriendsIfNeeded: friendsList.fetchIfNeeded,
  invalidateFriends: friendsList.invalidate,

  //followings
  fetchFollowingsIfNeeded: followings.fetchIfNeeded,
  invalidateFollowingsDetails: followings.invalidate,

  fetchChallengesLeaderboardsViewAllIfNeeded:
    challengesLeaderboardsViewAll.fetchIfNeeded,
  invalidateChallengesLeaderboardsViewAll:
    challengesLeaderboardsViewAll.invalidate,
  invalidateAllChallengesLeaderboardsViewAll:
    challengesLeaderboardsViewAll.invalidateAll
};

const mapStateToProps = state => {
  const { friends, profiles, challenges } = state;

  const { items: friendsList } = friends.friendsList || {
    items: []
  };

  const { items: followingsList } = profiles.followingsList || {
    items: []
  };

  return {
    userId: state.user.item.id,
    friendsList,
    followingsList,
    gamesPlayed: state.user.item ? state.user.item.gamesPlayed : 0,
    allOnlineUserList: state.socketusers.allOnlineUserList,
    lpage: challenges.ChallengesLeaderboardsViewAll.page,
    isLastPage: challenges.ChallengesLeaderboardsViewAll.isLastPage,
    leaderboard: challenges.ChallengesLeaderboardsViewAll.items
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Leaderboard);
