import React, { Component } from "react";
import { connect } from "react-redux";
import CardImage from "../../assets/images/card-sm.jpg";
import { getNoOfDay } from "../../utils/date";
import { refactorTitle } from "./../../utils/misc";
import { tournamentGameCardImages } from "./../../utils/gameImages";
import adv10 from "../../assets/images/adv/adv-5.png";
import adv10Nick from "../../assets/images/adv/adv-nick.png";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";

class TournamentCardComponent extends Component {
  addDefaultSrc(ev) {
    ev.target.src = CardImage;
  }
  challengeType;

  prepareTagClass() {
    if (this.props.challengeType && this.props.challengeType === "Live") {
      return "gtmJoinTournament-Tournament" + this.props.order;
    }
    if (this.props.challengeType && this.props.challengeType === "Quick") {
      return "gtmJoinQuickPlay-QuickPlay" + this.props.order;
    }
    if (this.props.challengeType && this.props.challengeType === "Upcoming") {
      return "gtmJoinUpcoming-Upcoming" + this.props.order;
    }
  }

  gtmJoinUpcoming;

  render() {
    const {
      brandName,
      brandAvatar,
      name,
      timeOfStart,
      timeOfEnd,
      organizerId,
      allOnlineUserList,
      t
    } = this.props;
    let date = getNoOfDay(timeOfStart, timeOfEnd);
    date = date
      .replace("Ended", t("ended"))
      .replace("days", t("days"))
      .replace("Ends", t("ends"))
      .replace("hours", t("hours"))
      .replace("Starting in", t("starttingIn"))
      .replace("day", t("day"));
    const lang = i18n.language;
    return (
      <>
        {this.props.type && this.props.type === "ad" ? (
          <div
            className="card popular-arcadex d-flex justify-content-center align-items-center"
            style={{ width: "300px" }}
          >
            {/* <img
              className="w-100"
              src={
                process.env.REACT_APP_PLATFORM === "nickx" ? adv10Nick : adv10
              }
              alt="Advertisement"
            /> */}
          </div>
        ) : (
            <a
              href={"/" + lang + "/challenge/" + this.props.id}
              className={this.prepareTagClass()}
            >
              <div className={this.props.cardClass}>
                <div
                  className={
                    "card-header light" +
                    (process.env.REACT_APP_PLATFORM === "nickx" ? " nickx" : "")
                  }
                >
                  {date}
                </div>
                <img
                  className="card-img"
                  src={tournamentGameCardImages[this.props.casualGameId]}
                  alt=""
                  onError={this.addDefaultSrc}
                />
                <div className="card-body">
                  <span className="brand-img-parent">
                    <figure>
                      <img
                        src={brandAvatar}
                        alt="Popular NickX"
                        className={this.props.brandAvatarClass}
                      />
                      {allOnlineUserList.includes(organizerId) ? (
                        <figcaption className="online-72" />
                      ) : null}
                      <figcaption
                        className={
                          "mt-2 " +
                          (process.env.REACT_APP_PLATFORM === "nickx"
                            ? "text-orange"
                            : "semi-bold ")
                        }
                      >
                        {brandName}
                      </figcaption>
                    </figure>
                  </span>
                  <h4 className="bold">{refactorTitle(name)}</h4>
                </div>
              </div>
            </a>
          )}
      </>
    );
  }
}

const defaultProps = {
  id: 1,
  brandName: "MTN",
  brandAvatar: "",
  challengeImageUrl: CardImage,
  name: "test",
  timeOfStart: "2018-10-16 18:30:00+00",
  timeOfEnd: "2018-10-23 06:42:00+00",
  cardClass: "card",
  brandAvatarClass: "profile-pic-72",
  type: "card"
};
TournamentCardComponent.defaultProps = defaultProps;

const mapStateToProps = state => {
  return {
    isLoggedIn: state.login.isLoggedIn,
    allOnlineUserList: state.socketusers.allOnlineUserList
  };
};

export default connect(
  mapStateToProps,
  defaultProps
)(withTranslation()(TournamentCardComponent));
