import React, { Component } from "react";
import { connect } from "react-redux";
import { changePassword } from "../../../../actions/modals";
import {
  togglePasswordEyeChangePass,
  togglePasswordEyeChangeOldPass
} from "../../../../actions/toggles";
import "./ChangePasswordModal.scss";
import Modal from "../../Modal";
import AppInputComponent from "../../../../layouts/components/sidebars/AppInputComponent";
import { changePasswordForm } from "../../../../actions/forms";
import { APP_MESSAGES } from "../../../../services/config/messages";
import { withTranslation } from "react-i18next";
import {
  validatePassword,
  validatePasswordRepeat
} from "../../../../utils/validators";
class ChangePasswordModal extends Component {
  constructor(props) {
    super(props);
    this.showChangePassword = this.showChangePassword.bind(this);
    this.hideChangePassword = this.hideChangePassword.bind(this);
  }
  showChangePassword() {
    this.props.changePasswordOpen();
  }
  hideChangePassword() {
    this.props.changePasswordClose();
  }
  render() {
    let Error;
    let Success;
    Success = this.props.changePasswordForm.fields.newPassword.success
      ? APP_MESSAGES[
          this.props.changePasswordForm.fields.newPassword.successMsg
        ](
          this.props.changePasswordForm.fields.newPassword.successPayload,
          () => {
            this.props.changePasswordFormResetSuccess("newPassword");
          }
        )
      : props => <>{""}</>;

    Error = this.props.changePasswordForm.fields.newPassword.error
      ? APP_MESSAGES[this.props.changePasswordForm.fields.newPassword.errorMsg](
          this.props.changePasswordForm.fields.newPassword.errorPayload,
          () => {
            this.props.changePasswordFormResetError("newPassword");
          }
        )
      : props => <>{""}</>;
    const { t } = this.props;
    return (
      <>
        <Modal
          showCloseBtn={true}
          modalCrossSvgClass={true}
          handleHide={this.hideChangePassword}
          isOpen={this.props.isChangePasswordOpen}
          disableOuterClick={false}
          modalClass="modal modal-change-password"
          withError={
            this.props.changePasswordForm.fields.newPassword.error ||
            this.props.changePasswordForm.fields.newPassword.success
          }
        >
          <div className="card-popup change-password">
            <div className="row">
              <div className="col-sm-12 text-center mb-2 mb-sm-4">
                <h4 className="mb-3 bold">{t("changePassword")}</h4>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <Error />
                <Success />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 text-left">
                <form
                  onSubmit={e => {
                    e.preventDefault();

                    if (!this.props.changePasswordForm.isSubmitting) {
                      const currentPassword = this.props.changePasswordForm
                        .fields.currentPassword.value;
                      const password = this.props.changePasswordForm.fields
                        .newPassword.value;
                      const renewPassword = this.props.changePasswordForm.fields
                        .renewPassword.value;

                      if (!validatePassword(currentPassword)) {
                        this.props.changePasswordFormSetError(
                          "newPassword",
                          "PASSWORD_LENGTH"
                        );
                        return false;
                      }
                      if (!validatePassword(password)) {
                        this.props.changePasswordFormSetError(
                          "newPassword",
                          "PASSWORD_LENGTH"
                        );
                        return false;
                      } else if (
                        !validatePasswordRepeat(password, renewPassword)
                      ) {
                        this.props.changePasswordFormSetError(
                          "newPassword",
                          "COMPARE_RESET_PASSWORD"
                        );
                        return false;
                      }
                      let postParam = {
                        oldPassword: currentPassword,
                        newPassword: password
                      };
                      this.props.changePasswordFormSubmit(postParam);
                    }
                  }}
                >
                  <div className="form-group with-lable custom-top">
                    <AppInputComponent
                      withDebounce={false}
                      type={
                        this.props.isPasswordOldVisible ? "text" : "password"
                      }
                      value={
                        this.props.changePasswordForm.fields.currentPassword
                          .value
                      }
                      labelText={t("enterCurrentPw")}
                      divClass="form-group with-lable mt-3"
                      onChange={event => {
                        this.props.changePasswordFormResetError("newPassword");
                        this.props.changePasswordFormUpdateField(
                          "currentPassword",
                          event.target.value
                        );
                      }}
                      inputAddon={() => (
                        <span
                          toggle="#password-field"
                          className={
                            "field-icon toggle-password" +
                            (this.props.isPasswordOldVisible
                              ? " eye-close"
                              : " eye-open")
                          }
                          onClick={this.props.passwordEyeChangeOldPass}
                        />
                      )}
                    />
                    <AppInputComponent
                      withDebounce={false}
                      type={this.props.isPasswordVisible ? "text" : "password"}
                      labelText={t("enterNewPw")}
                      divClass="form-group with-lable mt-5"
                      value={
                        this.props.changePasswordForm.fields.newPassword.value
                      }
                      onChange={event => {
                        this.props.changePasswordFormResetError("newPassword");
                        this.props.changePasswordFormUpdateField(
                          "newPassword",
                          event.target.value
                        );
                      }}
                      inputAddon={() => (
                        <span
                          toggle="#password-field"
                          className={
                            "field-icon toggle-password" +
                            (this.props.isPasswordVisible
                              ? " eye-close"
                              : " eye-open")
                          }
                          onClick={this.props.passwordEyeChangePass}
                        />
                      )}
                    />
                    <AppInputComponent
                      withDebounce={false}
                      type="password"
                      labelText={t("reEnterNewPw")}
                      divClass="form-group with-lable mt-5"
                      value={
                        this.props.changePasswordForm.fields.renewPassword.value
                      }
                      onChange={event => {
                        this.props.changePasswordFormResetError("newPassword");
                        this.props.changePasswordFormUpdateField(
                          "renewPassword",
                          event.target.value
                        );
                      }}
                    />
                  </div>

                  <button
                    type="submit"
                    className="btn btn-pink light btn-block mt-5"
                  >
                    {t("changePassword")}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}
const mapDispatchToProps = {
  changePasswordOpen: changePassword.open,
  changePasswordClose: changePassword.close,

  passwordEyeChangeOldPass: togglePasswordEyeChangeOldPass.toggle,
  passwordEyeChangePass: togglePasswordEyeChangePass.toggle,

  changePasswordFormSubmit: changePasswordForm.submit,
  changePasswordFormReset: changePasswordForm.reset,
  changePasswordFormResetField: changePasswordForm.resetField,
  changePasswordFormUpdateField: changePasswordForm.updateField,
  changePasswordFormSetError: changePasswordForm.setError,
  changePasswordFormSetSuccess: changePasswordForm.setSuccess,
  changePasswordFormResetError: changePasswordForm.resetError,
  changePasswordFormResetSuccess: changePasswordForm.resetSuccess
};

const mapStateToProps = state => {
  return {
    isPasswordVisible: state.toggles.pwEyeChangePw,
    isPasswordOldVisible: state.toggles.pwEyeChangeOldPw,
    isChangePasswordOpen: state.modals.isChangePasswordOpen,
    userId: state.user.item.id,
    changePasswordForm: state.forms.changePasswordForm
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ChangePasswordModal));
