import React, { Component } from "react";
//images
import CardImage from "../../../assets/images/card-sm.jpg";

//utils
import { refactorTitle } from "./../../../utils/misc";
import { getNoOfDay } from "../../../utils/date";
import { withTranslation } from "react-i18next";
import i18n from "../../../i18n";

class DashboardQuickPlayCardComponent extends Component {
  addDefaultSrc(ev) {
    ev.target.src = CardImage;
  }

  prepareTagClass(brandName) {
    return " gtmJoinQuickPlay-QuickPlay" + this.props.order;
  }

  render() {
    const { casualFreeGameImageUrl } = this.props;
    let date = getNoOfDay(this.props.timeOfStart, this.props.timeOfEnd);
    date = date
      .replace("Ends", this.props.t("ends"))
      .replace("hours", this.props.t("hours"));
    const lang = i18n.language;
    return (
      <a
        href={"/" + lang + "/challenge/" + this.props.id}
        className={this.prepareTagClass(this.props.brandName)}
      >
        <div className="card quick-play">
          <div
            className={
              "card-header light" +
              (process.env.REACT_APP_PLATFORM === "nickx" ? " nickx" : "")
            }
          >
            {date}
          </div>
          <img
            className="card-img"
            // src={casualFreeGameImageUrl}
            src={casualFreeGameImageUrl ? casualFreeGameImageUrl : CardImage}
            alt={this.props.name}
            onError={this.addDefaultSrc}
          />
          <h4 className="mt-3 bold" title={this.props.name}>
            {refactorTitle(this.props.name, 15)}
          </h4>
        </div>
      </a>
    );
  }
}

const defaultProps = {
  id: 1,
  challengeTitle: "Title of championship here",
  casualFreeGameImageUrl: CardImage,
  challengeImageUrl: CardImage,
  timeOfStart: "2018-10-16 18:30:00+00",
  timeOfEnd: "2018-10-23 06:42:00+00"
};

DashboardQuickPlayCardComponent.defaultProps = defaultProps;

export default withTranslation()(DashboardQuickPlayCardComponent);
