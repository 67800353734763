import React, { Component } from "react";
import "./RafflesComponent.scss";
import navArrowRightBlue from "../../assets/images/svg/nav-arrow-right-blue.svg";
import TokenImage from "../../assets/images/token.png";
import RafflesCardContainer from "./RafflesCardContainer";
import { withTranslation } from "react-i18next";

class RafflesComponent extends Component {
  constructor(props) {
    super(props);

    this.loadRaffles = this.loadRaffles.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
  }

  handleRefresh() {
    this.loadRaffles();
  }

  loadRaffles() {
    const {
      fetchRafflesIfNeeded,
      invalidateRaffles,
      fetchMyActiveRafflesIfNeeded,
      invalidateMyActiveRaffles
    } = this.props;

    invalidateRaffles();
    fetchRafflesIfNeeded({ page: 1 });

    invalidateMyActiveRaffles();
    fetchMyActiveRafflesIfNeeded({ page: "" });
  }

  componentDidMount() {
    this.loadRaffles();
    window.scrollTo(0, 0);
  }

  componentDidUpdate() {
    document.body.classList.remove("modal-open");
  }

  render() {
    const { rafflesList, myActiveRafflesList, tokensInWallet, t } = this.props;
    return (
      <>
        <section className="[ container-fluid ] enter-raffles-wrapper">
          <div className="row justify-content-center">
            <div
              className={
                "col-xl-3 col-10  bg-wallet" +
                (process.env.REACT_APP_PLATFORM === "nickx" ? " nickx" : "")
              }
            >
              <div className="row no-gutters d-flex justify-content-center align-items-center">
                <div className="col-auto">
                  <h4 className="text-white semi-bold">
                    {" "}
                    <span className="bold">
                      {tokensInWallet ? tokensInWallet : 0}
                    </span>{" "}
                    {t("tokensInWallet")}
                  </h4>
                </div>
                <div className="col-auto">
                  <img src={TokenImage} className="ml-2" alt="" />
                </div>
              </div>
            </div>
          </div>

          <div className="padder-main">
            <div className="row">
              <div className="container">
                <div className="row d-flex align-items-center mb-30 ">
                  <div className="col-8 d-flex justify-content-start">
                    <h2 className="bold">{t("enterRaffle")}</h2>
                  </div>
                  {rafflesList && rafflesList.length > 0 ? (
                    <div className="col text-right d-flex justify-content-end">
                      <a
                        href="raffles/all"
                        className="view-all d-flex align-items-center"
                      >
                        {t("viewAll")}
                        <span>
                          <img src={navArrowRightBlue} alt="" />
                        </span>
                      </a>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row mb-30">
                  <div className="col-sm-12">
                    <h5>{t("useYourToken")} </h5>
                  </div>
                </div>
              </div>
            </div>

            <div className="row livetournament">
              {rafflesList && rafflesList.length > 0 ? (
                <RafflesCardContainer
                  list={
                    rafflesList
                      ? rafflesList.map((ele, inde) => {
                          return {
                            ...ele
                          };
                        })
                      : []
                  }
                  raffleType="enter"
                />
              ) : (
                <div className="col">
                  <h6 className="text-center">{t("noRaffleFound")}</h6>
                </div>
              )}
            </div>
          </div>
        </section>
        <section className="[ container-fluid ] active-raffle section-border-top">
          <div className="padder-main">
            <div className="row">
              <div className="container">
                <div className="row d-flex align-items-center mb-30 ">
                  <div className="col-12 d-flex justify-content-start">
                    <h2 className="bold">{t("myActiveRaffle")}</h2>
                  </div>
                  {myActiveRafflesList && myActiveRafflesList.length > 0 ? (
                    <div className="col text-right d-flex justify-content-end">
                      <a
                        href="raffles/active/all"
                        className="view-all d-flex align-items-center"
                      >
                        {t("viewAll")}
                        <span>
                          <img src={navArrowRightBlue} alt="" />
                        </span>
                      </a>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row mb-30">
                  <div className="col-sm-12">
                    <h5>{t("chanceOfWin")}</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="row livetournament">
              {myActiveRafflesList && myActiveRafflesList.length > 0 ? (
                <RafflesCardContainer
                  list={
                    myActiveRafflesList
                      ? myActiveRafflesList.map((ele, inde) => {
                          return {
                            ...ele
                          };
                        })
                      : []
                  }
                  raffleType="active"
                />
              ) : (
                <div className="col">
                  <h6 className="text-center">{t("noRaffleFound")}</h6>
                </div>
              )}
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default withTranslation()(RafflesComponent);
