import React, { Component } from "react";
import CardImage from "../../assets/images/poo_default.png";
import PrizeIconFirst from "../../assets/images/svg/x-1.svg";
import PrizeIconSecond from "../../assets/images/svg/x-2.svg";
import PrizeIconThird from "../../assets/images/svg/x-3.svg";
import PrizeIconFourth from "../../assets/images/svg/x-4.svg";
import PrizeDefaultImg from "../../assets/images/poo_default.png";
import "./TournamentPoolCardComponent.scss";
import { ordinalSuffix } from "../../utils/misc";
import adv10 from "../../assets/images/adv/adv-5.png";
import adv10Nickx from "../../assets/images/adv/adv-nick.png";
import { withTranslation } from "react-i18next";

class TournamentPoolCardComponent extends Component {
  addDefaultSrc(ev) {
    ev.target.src = PrizeDefaultImg;
  }
  getPlace(no) {
    const { t } = this.props;
    switch (no) {
      case 1:
        return {
          image: PrizeIconFirst,
          name: t("firstPrize"),
          barClass: "first"
        };
      case 2:
        return {
          image: PrizeIconSecond,
          name: t("second") + t("prize"),
          barClass: "second"
        };
      case 3:
        return {
          image: PrizeIconThird,
          name: t("third") + t("prize"),
          barClass: "third"
        };
      case 4:
        return {
          image: PrizeIconFourth,
          name: t("fourth") + t("prize"),
          barClass: "fourth"
        };
      case 8:
        return {
          image: PrizeIconFourth,
          name: ordinalSuffix(5) + " - " + ordinalSuffix(8),
          barClass: "fourth"
        };
      case 16:
        return {
          image: PrizeIconFourth,
          name: ordinalSuffix(9) + " - " + ordinalSuffix(16),
          barClass: "fourth"
        };
      case 32:
        return {
          image: PrizeIconFourth,
          name: ordinalSuffix(17) + " - " + ordinalSuffix(32),
          barClass: "fourth"
        };
      case 64:
        return {
          image: PrizeIconFourth,
          name: ordinalSuffix(33) + " - " + ordinalSuffix(64),
          barClass: "fourth"
        };
      case 128:
        return {
          image: PrizeIconFourth,
          name: ordinalSuffix(65) + " - " + ordinalSuffix(128),
          barClass: "fourth"
        };
      case 256:
        return {
          image: PrizeIconFourth,
          name: ordinalSuffix(129) + " - " + ordinalSuffix(256),
          barClass: "fourth"
        };
      case 512:
        return {
          image: PrizeIconFourth,
          name: ordinalSuffix(257) + " - " + ordinalSuffix(512),
          barClass: "fourth"
        };
      case 1024:
        return {
          image: PrizeIconFourth,
          name: ordinalSuffix(513) + " - " + ordinalSuffix(1024),
          barClass: "fourth"
        };
      case 2048:
        return {
          image: PrizeIconFourth,
          name: ordinalSuffix(1025) + " - " + ordinalSuffix(2048),
          barClass: "fourth"
        };
      case 4096:
        return {
          image: PrizeIconFourth,
          name: ordinalSuffix(2049) + " - " + ordinalSuffix(4096),
          barClass: "fourth"
        };
      default:
        return {
          image: PrizeIconFourth,
          name: "no",
          barClass: "fourth"
        };
    }
  }

  getRewardTitle() {
    const { t } = this.props;
    let itemsTitle = "";
    let pointsTitle = "";

    // if (this.props.list) {
    //   this.props.list.map(reward => {
    //     if (reward.type.toLowerCase() === "items") itemsTitle = reward.value;

    //     if (reward.type.toLowerCase() === "points")
    //       pointsTitle = reward.value + " Tokens";
    //   });
    // }

    if (this.props.itemDescription) itemsTitle = this.props.itemDescription;
    if (this.props.points) pointsTitle = this.props.points + t("tokens");

    if (itemsTitle !== "" && pointsTitle !== "") {
      return (
        <h4 className="bold">
          {itemsTitle}
          <span> + {pointsTitle}</span>
        </h4>
      );
    } else if (itemsTitle !== "") {
      return <h4 className="bold">{itemsTitle}</h4>;
    } else {
      return (
        <h4 className="bold">
          {pointsTitle}
          <br />
        </h4>
      );
    }
  }

  render() {
    const { image, name, barClass } = this.getPlace(this.props.place);
    return (
      <>
        {this.props.type && this.props.type === "ad" ? (
          <div
            className="card popular-arcadex d-flex justify-content-center align-items-center"
            style={{ width: "300px" }}
          >
            {/* <img
              className="w-100"
              src={
                process.env.REACT_APP_PLATFORM === "nickx" ? adv10Nickx : adv10
              }
              alt="Advertisement"
            /> */}
          </div>
        ) : (
          <div className={"card " + this.props.cardLgClass}>
            <img
              className="card-img"
              src={this.props.itemUrl ? this.props.itemUrl : CardImage}
              alt=""
              onError={this.addDefaultSrc}
            />
            <div
              className={
                "card-body " +
                barClass +
                " d-flex flex-column align-items-center justify-content-center"
              }
            >
              <figure>
                <div className="oval">
                  <div className="mask">
                    <img src={image} alt="Popular NickX" />
                  </div>
                </div>
                <figcaption className="text-center">
                  <h6 className="bold">{name}</h6>
                </figcaption>
              </figure>
              {this.getRewardTitle()}
            </div>
          </div>
        )}
      </>
    );
  }
}

export default withTranslation()(TournamentPoolCardComponent);
