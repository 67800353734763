import React, { Component } from "react";
import "./MyTournamentsComponent.scss";
import TournamentCardComponent from "../Tournaments/TournamentCardComponent";
import loadinggif from "../../assets/images/loading.gif";
import { withTranslation } from "react-i18next";

class MyTournamentsComponent extends Component {
  constructor(props) {
    super(props);
    this.loadMytournaments = this.loadMytournaments.bind(this);
    this.loadMytournamentsFinished = this.loadMytournamentsFinished.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.loadMytournaments();
    this.loadMytournamentsFinished();
  }

  loadMytournaments() {
    const {
      fetchMyTournamentsIfNeeded,
      invalidateAllMyTournaments,
      userId
    } = this.props;
    invalidateAllMyTournaments();
    fetchMyTournamentsIfNeeded({ page: 1 });
  }

  loadMytournamentsFinished() {
    const {
      fetchMyTournamentsEndedIfNeeded,
      invalidateAllMyTournamentsEnded,
      userId
    } = this.props;
    invalidateAllMyTournamentsEnded();
    fetchMyTournamentsEndedIfNeeded({ page: 1 });
  }

  render() {
    let {
      mytournamentPlayingList,
      isFetchingPlaying,
      isPlayingRecordFinish,
      mytournamentEndedList,
      isFetchingEnded,
      isEndedRecordFinish,
      isMyTournamentsPlaying,
      myTournamentToggle,
      mpage,
      fpage,
      userId,
      t
    } = this.props;
    return (
      <section className="container-fluid my-tournament-wrapper">
        <div className="row">
          <div className="container">
            <div className="padder-main">
              <div className="row d-flex align-items-center mb-30 ">
                <div className="col-8 d-flex justify-content-start">
                  <h2 className="bold">{t("myTournament")}</h2>
                </div>
                <div className="col text-right d-flex justify-content-end">
                  {!isMyTournamentsPlaying ? (
                    <span
                      onClick={() => {
                        myTournamentToggle();
                      }}
                      className="view-all d-flex align-items-center"
                    >
                      {t("showEnded")}
                    </span>
                  ) : (
                    <span
                      onClick={() => {
                        myTournamentToggle();
                      }}
                      className="view-all d-flex align-items-center"
                    >
                       {t("showPlaying")}
                    </span>
                  )}
                </div>
              </div>

              <div className="row card-wrapper d-flex justify-content-center justify-content-sm-start">
                {!isMyTournamentsPlaying && mytournamentPlayingList.length > 0
                  ? mytournamentPlayingList.map((tournament, i) => (
                      <div className="col-auto mb-4" key={i}>
                        <TournamentCardComponent
                          {...tournament}
                          cardClass="card card-my-tournament"
                          brandAvatarClass="profile-pic-56"
                          order={i + 1}
                        />
                      </div>
                    ))
                  : ""}

                {isMyTournamentsPlaying && mytournamentEndedList.length > 0
                  ? mytournamentEndedList.map((tournament, i) => (
                      <div className="col-auto mb-4" key={i}>
                        <TournamentCardComponent
                          {...tournament}
                          cardClass="card card-my-tournament"
                          brandAvatarClass="profile-pic-56"
                          order={i + 1}
                        />
                      </div>
                    ))
                  : ""}
              </div>
              <div className="row mb-30 text-center">
                <div className="col-sm-12">
                  {!isMyTournamentsPlaying ? (
                    mytournamentPlayingList.length > 0 ? (
                      !isPlayingRecordFinish ? (
                        <button
                          onClick={() => {
                            this.props.invalidateMyTournaments();
                            this.props.fetchMyTournamentsIfNeeded({
                              page: mpage
                            });
                          }}
                          className="btn btn-pink dark btn-xs"
                        >
                          {t("loadMore")}
                        </button>
                      ) : isFetchingPlaying ? (
                        <img src={loadinggif} alt="" />
                      ) : (
                        ""
                      )
                    ) : isFetchingPlaying ? (
                      <img className="text-center" src={loadinggif} alt="" />
                    ) : (
                      <span>{t("noTournamentFound")}</span>
                    )
                  ) : mytournamentEndedList.length > 0 ? (
                    !isEndedRecordFinish ? (
                      <button
                        onClick={() => {
                          this.props.invalidateMyTournamentsEnded();
                          this.props.fetchMyTournamentsEndedIfNeeded({
                            page: fpage
                          });
                        }}
                        className="btn btn-pink dark btn-xs"
                      >
                        {t("loadMore")}
                      </button>
                    ) : isFetchingEnded ? (
                      <img src={loadinggif} alt="" />
                    ) : (
                      ""
                    )
                  ) : isFetchingEnded ? (
                    <img className="text-center" src={loadinggif} alt="" />
                  ) : (
                    <span>{t("noTournamentFound")}</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default withTranslation()(MyTournamentsComponent);

