import React, { Component } from "react";
import TournamentCardComponent from "../../Tournaments/TournamentCardComponent";
import loadinggif from "../../../assets/images/loading.gif";
import { withTranslation } from "react-i18next";
class TournamentsUpcomingComponent extends Component {
  componentDidMount() {
    const {
      fetchUpcomingChallengesViewAllIfNeeded,
      invalidateAllUpcomingChallengesViewAll
    } = this.props;
    invalidateAllUpcomingChallengesViewAll();
    fetchUpcomingChallengesViewAllIfNeeded({
      brandId: "",
      page: 1
    });
  }
  componentDidUpdate() {
    document.body.classList.remove("modal-open");
  }

  render() {
    let { page ,t } = this.props;
    return (
      <section className="container-fluid my-tournament-wrapper">
        <div className="row">
          <div className="container">
            <div className="padder-main">
              <div className="row d-flex align-items-center mb-30 ">
                <div className="col-8 d-flex justify-content-start">
                  <h2 className="bold">{t("upcoming")} {t("navTournaments")} </h2>
                </div>
              </div>

              <div className="row card-wrapper d-flex justify-content-center justify-content-sm-start">
                {this.props.upcomingViewAll.items.length > 0
                  ? this.props.upcomingViewAll.items.map((tournament, i) => (
                      <div className="col-auto mb-4" key={i}>
                        <TournamentCardComponent
                          {...tournament}
                          cardClass="card card-my-tournament"
                          brandAvatarClass="profile-pic-56"
                          challengeType="Upcoming"
                          order={i + 1}
                        />
                      </div>
                    ))
                  : ""}
              </div>
              <div className="row mb-30 text-center">
                <div className="col-sm-12">
                  {this.props.upcomingViewAll.items.length > 0 ? (
                    !this.props.upcomingViewAll.isLastPage ? (
                      <button
                        onClick={() => {
                          this.props.invalidateUpcomingChallengesViewAll();
                          this.props.fetchUpcomingChallengesViewAllIfNeeded({
                            brandId: "",
                            page: page
                          });
                        }}
                        className="btn btn-pink dark btn-xs"
                      >
                        {t("loadMore")}
                      </button>
                    ) : this.props.upcomingViewAll.isFetching ? (
                      <img src={loadinggif} alt="" />
                    ) : (
                      ""
                    )
                  ) : this.props.upcomingViewAll.isFetching ? (
                    <img className="text-center" src={loadinggif} alt="" />
                  ) : (
                    <span>{t("noTournamentFound")}</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withTranslation()(TournamentsUpcomingComponent);
