export const tournamentGameCardImages = {
    "1" : "https://api.arcadex.co/images/3",
"2" : "https://api.arcadex.co/images/6",
"6" : "https://api.arcadex.co/images/7",
"7" : "https://api.arcadex.co/images/14",
"8" : "https://api.arcadex.co/images/10",
"9" : "https://api.arcadex.co/images/17",
"10" : "https://api.arcadex.co/images/18",
"11" : "https://api.arcadex.co/images/19",
"12" : "https://api.arcadex.co/images/20",
"13" : "https://api.arcadex.co/images/21",
"15" : "https://api.arcadex.co/images/22",
"16" : "https://api.arcadex.co/images/24",
"17" : "https://api.arcadex.co/images/25",
"19" : "https://api.arcadex.co/images/26",
"20" : "https://api.arcadex.co/images/29",
"21" : "https://api.arcadex.co/images/30",
"22" : "https://api.arcadex.co/images/31",
"29" : "https://api.arcadex.co/images/33",
"30" : "https://api.arcadex.co/images/37",
"31" : "https://api.arcadex.co/images/41",
"32" : "https://api.arcadex.co/images/42",
"33" : "https://api.arcadex.co/images/43",
"34" : "https://api.arcadex.co/images/44",
"35" : "https://api.arcadex.co/images/45",
"36" : "https://api.arcadex.co/images/46",
"37" : "https://api.arcadex.co/images/49",
"38" : "https://api.arcadex.co/images/52",
"39" : "https://api.arcadex.co/images/54",
"40" : "https://api.arcadex.co/images/55",
"41" : "https://api.arcadex.co/images/57",
"42" : "https://api.arcadex.co/images/58",
"49" : "https://api.arcadex.co/images/60",
"45" : "https://api.arcadex.co/images/62",
"50" : "https://api.arcadex.co/images/65",
"46" : "https://api.arcadex.co/images/69",
"52" : "https://api.arcadex.co/images/71",
"44" : "https://api.arcadex.co/images/75",
"43" : "https://api.arcadex.co/images/78",
"51" : "https://api.arcadex.co/images/79",
"47" : "https://api.arcadex.co/images/80",
"48" : "https://api.arcadex.co/images/81",
};

export const freeGameCardImages = {
    "1" : "https://api.arcadex.co/images/39",
"2" : "https://api.arcadex.co/images/13",
"6" : "https://api.arcadex.co/images/36",
"7" : "https://api.arcadex.co/images/59",
"8" : "https://api.arcadex.co/images/63",
"9" : "https://api.arcadex.co/images/9",
"10" : "https://api.arcadex.co/images/48",
"11" : "https://api.arcadex.co/images/1",
"12" : "https://api.arcadex.co/images/28",
"13" : "https://api.arcadex.co/images/8",
"15" : "https://api.arcadex.co/images/16",
"16" : "https://api.arcadex.co/images/32",
"17" : "https://api.arcadex.co/images/35",
"19" : "https://api.arcadex.co/images/2",
"20" : "https://api.arcadex.co/images/27",
"21" : "https://api.arcadex.co/images/50",
"22" : "https://api.arcadex.co/images/51",
"29" : "https://api.arcadex.co/images/4",
"30" : "https://api.arcadex.co/images/34",
"31" : "https://api.arcadex.co/images/40",
"32" : "https://api.arcadex.co/images/38",
"33" : "https://api.arcadex.co/images/56",
"34" : "https://api.arcadex.co/images/38",
"35" : "https://api.arcadex.co/images/12",
"36" : "https://api.arcadex.co/images/11",
"37" : "https://api.arcadex.co/images/61",
"38" : "https://api.arcadex.co/images/15",
"39" : "https://api.arcadex.co/images/5",
"40" : "https://api.arcadex.co/images/59",
"41" : "https://api.arcadex.co/images/53",
"42" : "https://api.arcadex.co/images/47",
"49" : "https://api.arcadex.co/images/64",
"45" : "https://api.arcadex.co/images/66",
"50" : "https://api.arcadex.co/images/67",
"46" : "https://api.arcadex.co/images/68",
"52" : "https://api.arcadex.co/images/70",
"44" : "https://api.arcadex.co/images/72",
"43" : "https://api.arcadex.co/images/73",
"51" : "https://api.arcadex.co/images/74",
"47" : "https://api.arcadex.co/images/76",
"48" : "https://api.arcadex.co/images/77",
}