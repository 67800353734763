import React, { Component } from "react";
import ReactDOM from "react-dom";
import GameCardContainer from "../Games/GameCardContainer";
import TournamentCardContainer from "../Tournaments/TournamentCardContainer";
import FeaturedCardContainer from "../FeaturedIn/FeaturedCardContainer";
import { parseQuery, getContainerLeftWidth, isNgCountry } from "../../utils/misc";
import subscribeDevice3xImageNickx from "../../assets/images/subscribe-device@3x-nick.png";
import subscribeDevice3xImage from "../../assets/images/subscribe-device@3x.png";
import subscribeImage from "../../assets/images/subscribe@3x.png";
import playGameImage from "../../assets/images/play@3x.png";
import winPrizeImage from "../../assets/images/win@3x.png";
import navArrowRightWhite from "../../assets/images/svg/nav-arrow-right-white.svg";
import platformImage from "../../assets/images/platform@3x.png";
import tagImageNick from "../../assets/images/tag@3x-ticket-nick.png";
import tagImage from "../../assets/images/tag@3x.png";
import homeImageNick from "../../assets/images/home-banner-nick.png";
import homeImage from "../../assets/images/home-banner.png";
import "swiper/dist/css/swiper.css";
import "./HomeComponent.scss";
import FeaturedImage1 from "../../assets/images/featuredLogo4.png";
import FeaturedImage2 from "../../assets/images/featuredLogo5.png";
import FeaturedImage3 from "../../assets/images/featuredLogo6.png";
import { validateEmail } from "../../utils/validators";
import AppInputComponent from "../../layouts/components/sidebars/AppInputComponent";
import UpdateDimension from "../Utils/UpdateDimension";
import { withTranslation, Trans } from "react-i18next";
import i18n from "../../i18n";

class HomeComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mlxContainer: 0
    };
    this.autoSetContainerImg = this.autoSetContainerImg.bind(this);
    this.loadChallenge = this.loadChallenge.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
    this.scrollToDiv = this.scrollToDiv.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.aboutRef = React.createRef();
    this.rafflesRef = React.createRef();
    this.tournamentsRef = React.createRef();
    this.newsRef = React.createRef();
  }

  //Homepage full left and right side image adjust as per container
  autoSetContainerImg() {
    this.setState((state, props) => {
      return { mlxContainer: getContainerLeftWidth() * -1 - 30 };
    });
  }
  handleRefresh() {
    this.loadChallenge();
  }
  updateDimensions() {
    this.autoSetContainerImg();
  }
  loadChallenge() {
    const {
      fetchChallengesLandingPopularIfNeeded,
      invalidateChallengesLandingPopular
    } = this.props;
    invalidateChallengesLandingPopular();
    fetchChallengesLandingPopularIfNeeded();
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.location.search) {
      const query = parseQuery(this.props.location.search);
      if (query.email && query.activationHash) {
        this.props.history.push(
          "/activate/" + query.email + "/" + query.activationHash
        );
      }
      if (query.email && query.resetPasswordHash) {
        this.props.history.push(
          "/resetpassword/" + query.email + "/" + query.resetPasswordHash
        );
      }
    }
    this.autoSetContainerImg();
    this.loadChallenge();
    if (this.props.location.hash) {
      this.scrollToDiv(this.props.location.hash);
    }
    if (this.props.location.resetPasswordHash) {
      this.scrollToDiv(this.props.location.resetPasswordHash);
    }
  }
  scrollToDiv(linkHref) {
    // //On Page load navigate to expected div
    let scrollX = linkHref === "#tournaments" ? -92 : -72;
    if (this.props.isLeftSidebarOpen) this.props.leftSidebarClose();
    const elmnt = this[linkHref.replace("#", "") + "Ref"];
    if (elmnt) {
      ReactDOM.findDOMNode(elmnt.current).focus();
      setTimeout(() => {
        window.scrollBy(0, scrollX);
      }, 100);
    }
  }
  render() {
    const { challengesList, t } = this.props;
    const lang = i18n.language;
    const gameData = [
      {
        id: 1,
        image: FeaturedImage1
      },
      {
        id: 2,
        image: FeaturedImage2
      },
      {
        id: 3,
        image: FeaturedImage3
      }
    ];
    return (
      <>
        <section className="container-fluid d-none d-lg-flex ">
          <div className="row">
            <div className="home-banner-wrapper w-100">
              <div className="container content-wrapper d-flex justify-content-start align-items-end">
                <div className="content">
                  <h1 className="text-white display-1">
                    <Trans i18nKey="joinTheGame">
                      Join the <br />
                      game
                    </Trans>
                  </h1>
                  <h5 className="text-white py-4">
                    <Trans i18nKey="description">
                      Play top games, win amazing prizes and <br />
                      earn rewards by competing in free-to-enter <br />
                      tournaments.
                    </Trans>
                  </h5>
                  <button
                    className="btn btn-pink btn-lg dark"
                    onClick={() => {
                      const that = this;
                      window.dataLayer &&  window.dataLayer.push({
                        'event': 'JoinHome',
                        'Click Classes': 'JoinHome',
                        'eventCallback': function () {
                          that.props.history.push("/" + lang + "/billing-info");
                        }
                      });
                      isNgCountry() &&  that.props.history.push("/" + lang + "/join-now");
                    }}
                  >
                    {this.props.t("joinNow")}
                  </button>
                </div>
              </div>
              <img
                src={
                  process.env.REACT_APP_PLATFORM === "nickx"
                    ? homeImageNick
                    : homeImage
                }
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
        </section>
        {/* MOBILE device */}
        <section
          className={
            "container-fluid home-banner-responsive default-sectop d-flex d-lg-none  justify-content-center align-items-end" +
            (process.env.REACT_APP_PLATFORM === "nickx" ? " nickx" : "")
          }
        >
          <div className="row empty-gutter ">
            <div className="col-sm-12">
              <div className="responsive-banner-content text-center">
                <h1 className="text-white display-1">
                  <Trans i18nKey="title">
                    Join the <br />
                    game
                  </Trans>
                </h1>
                <h5 className="py-4 text-white">
                  <Trans i18nKey="descriptionMobile">
                    Play top games, win amazing prizes and <br />
                    earn rewards by competing in{" "}
                    <span>
                      {" "}
                      free-to-enter <br />
                      tournaments.{" "}
                    </span>
                  </Trans>
                </h5>
              </div>
            </div>
          </div>
        </section>
        {/* END Join the game */}
        {/* START Icons */}
        <section
          className="container column-section"
          id="about"
          ref={this.aboutRef}
        >
          <div className="padder negative-margin-top">
            <div className="[ row justify-content-center ] gutter-edge">
              <div className="[ col-md-4 d-flex flex-column align-items-center text-center ] mb-5 mb-lg-auto">
                <div className="icon">
                  <img src={subscribeImage} alt="" />
                </div>
                <h4 className="text-uppercase bold">{t("subscribe")}</h4>
                <h5>
                  <Trans i18nKey="getInGame">
                    Get in the game. Sign up is <br />
                    quick and easy!
                  </Trans>
                </h5>
              </div>
              <div className="[ col-md-4 d-flex flex-column align-items-center text-center ] mb-5 mb-lg-auto">
                <div className="icon">
                  <img src={playGameImage} alt="" />
                </div>
                <h4 className="text-uppercase bold">{t("playGame")}</h4>
                <h5>
                  <Trans i18nKey="accessOverMost">
                    Access over 300 of the most <br />
                    popular games on your phone
                  </Trans>
                </h5>
              </div>
              { 
                !isNgCountry() ? 
                  <div className="[ col-md-4 d-flex flex-column align-items-center text-center ]">
                    <div className="icon">
                      <img src={winPrizeImage} alt="" />
                    </div>
                    <h4 className="text-uppercase bold">{t("winPrize")}</h4>
                    <h5>
                      <Trans i18nKey="goForGlory">
                        Go for glory! Enter tournaments <br />
                        to win cash and great prizes.
                      </Trans>
                    </h5>
                  </div> 
                : <></>
             }
            </div>
          </div>
        </section>
        {/* END Icons */}
        {/* START Nick X Mobi */}
        <section
          className={
            (process.env.REACT_APP_PLATFORM === "nickx"
              ? "nickxmobi-section"
              : "arcadexmobi-section") + " [ container-fluid ] "
          }
        >
          <div className="padder backstyle">
            <div className="row">
              <div className="container  arcadexmobi">
                <div className="row  ">
                  <div className="col-lg-5 arcadex-content ">
                    <h1 className="text-white h1-mb">
                      {process.env.REACT_APP_PLATFORM === "nickx"
                        ? "Nick"
                        : "Arcade"}{" "}
                      X Mobi
                    </h1>
                    <p className="[ text-white pr-5 ] d-none d-lg-block">
                      {process.env.REACT_APP_PLATFORM === "nickx"
                        ? "Nick"
                        : "Arcade"}{" "}
                      X Mobi{" "}
                      <Trans i18nKey="offerOver">
                        offers over 300 HTML5 <br />
                        titles from leading game developers and <br />
                        publishers optimised for phone and <br />
                        tablet devices. Perfect for gamers who <br />
                        like to play on the go!
                      </Trans>
                    </p>
                    <button
                      className="btn btn-link text-white pl-0 d-none d-lg-flex"
                      onClick={() => {
                        // this.props.loginOpen();
                        this.props.history.push("/" + lang + "/billing-login");
                      }}
                    >
                      {t("viewAllGames")}
                    </button>
                  </div>
                  <div className="col-lg-7 arcadex-slide-wrapper">
                    <GameCardContainer />
                    <p className="[ text-white ] d-block d-lg-none my-5 my-lg-auto w-md-60">
                      {process.env.REACT_APP_PLATFORM === "nickx"
                        ? "Nick"
                        : "Arcade"}{" "}
                      X Mobi {t("offerOverMobile")}{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <UpdateDimension
              onUpdate={this.updateDimensions}
              hasSetState={true}
            >
              <div className="row">
                <div className="container">
                  <div className="[ row d-flex   justify-content-end ] platform">
                    <div className="[ col-lg-8 ]">
                      <img
                        src={platformImage}
                        className="mlx-container-left"
                        alt=""
                        style={{
                          marginLeft: this.state.mlxContainer,
                          position: "relative",
                          height: "auto"
                        }}
                      />
                    </div>
                    <div className="[ col-lg-4 d-flex justify-content-start align-items-start align-items-lg-end flex-column ] platform-content">
                      <div className="w-md-60">
                        <h1 className="text-white h1-mb">{t("platforms")}</h1>
                        <p className="text-white">
                          <Trans i18nKey="anytimewhere">
                            Whether you're on your mobile phone or <br />
                            tablet, our games offer smooth and fully <br />
                            integrated play directly from the platform <br />
                            -anytime, anywhere.
                          </Trans>
                        </p>
                        <button
                          className="[ btn btn-pink btn-block dark mt-5 ]"
                          onClick={() => {
                            const that = this;
                            window.dataLayer &&  window.dataLayer.push({
                              'event': 'JoinHome',
                              'Click Classes': 'JoinHome',
                              'eventCallback': function () {
                                that.props.history.push("/" + lang + "/billing-info");
                              }
                            });
                            isNgCountry() &&  that.props.history.push("/" + lang + "/join-now");
                          }}
                        >
                          {t("startPlaying")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              { 
                !isNgCountry() ?   
                  <div className="row" id="raffles" ref={this.rafflesRef}>
                    <div className="container mb-5">
                      <div
                        className="[ row d-flex  justify-content-end ]"
                        id="token"
                      >
                        <div className="[ col-lg-6 col-xl-4 order-1 order-lg-0 ] token-conetnt">
                          <div className="w-md-60">
                            <h1 className="text-white h1-mb">
                              <Trans i18nKey="enterToken">
                                Earn Tokens & <br />
                                Enter Exciting <br />
                                Raffles
                              </Trans>
                            </h1>
                            <p className="text-white">
                              <Trans i18nKey="tokenConetnt">
                                Earn gold tokens by playing in <br />
                                tournaments and use them to buy raffle <br />
                                tickets. There are plenty of prizes up for <br />
                                grabs and anyone can win in the lucky <br />
                                draw. The more tokens you earn, the more <br />
                                times you can enter, and the higher your <br />
                                chances of winning!
                              </Trans>
                            </p>
                          </div>
                        </div>
                        <div className="[ col-lg-6 col-xl-8 d-flex justify-content-end align-items-center order-0 order-lg-1 ]">
                          <img
                            src={
                              process.env.REACT_APP_PLATFORM === "nickx"
                                ? tagImageNick
                                : tagImage
                            }
                            className="mlx-container-right"
                            alt=""
                            style={{
                              marginRight: this.state.mlxContainer,
                              position: "relative"
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div> 
                : <></>
              }
             
            </UpdateDimension>

            <div className="row" id="tournaments" ref={this.tournamentsRef}>
              <div className="container">
                <div className="row mb-26 d-flex align-items-center mt-0 mt-md-5 mt-lg-0">
                  <div className="col-8 d-flex justify-content-start">
                    <h2 className="text-white  populararcade-heading bold">
                      {t("popularOn")}{" "}
                      {process.env.REACT_APP_PLATFORM === "nickx"
                        ? "Nick"
                        : "Arcade"}{" "}
                      X
                    </h2>
                  </div>
                  <div className="col text-right d-flex justify-content-end">
                    <span
                      className="view-all-dashboard text-white d-flex align-items-center"
                      onClick={() => {
                        // this.props.loginOpen();
                        this.props.history.push("/" + lang + "/billing-login");
                      }}
                    >
                      {t("viewAll")}{" "}
                      <span>
                        <img src={navArrowRightWhite} alt="" />
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row populerarcadex">
              {challengesList.length ? (
                <TournamentCardContainer
                  list={challengesList.map((challenge, index) => {
                    return {
                      ...challenge,
                      cardClass: "card popular-arcadex",
                      cardHeaderClass: "card-header light"
                    };
                  })}
                />
              ) : (
                  <div className="container">
                    <div className="row d-flex align-items-center mt-0 mt-lg-0 ml-1">
                      <span
                        className="text-white display-2 py-4 btn-link a"
                        onClick={event => {
                          // this.props.registerOpen();
                          this.props.history.push("/" + lang + "/billing-info");
                        }}
                      >
                        {t("joinAndSee")}
                      </span>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </section>
        <section className="container-fluid bg-gradient-white about-arcadex-section">
          <div className="padder">
            <div className="row">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <h1 className="h1-mb">
                      {t("about")}{" "}
                      {process.env.REACT_APP_PLATFORM === "nickx"
                        ? "Nick"
                        : "Arcade"}{" "}
                      X
                    </h1>
                    <p>
                      {process.env.REACT_APP_PLATFORM === "nickx"
                        ? "Nick"
                        : "Arcade"}
                      X{" "}
                      <Trans i18nKey="gamingPortal">
                        is the ultimate gaming portal for eSports and casual
                        games. <br />
                        Test your skills, work your way to the top and win
                        awesome prizes in <br />
                        tournaments on PC, mobile and tablet.
                      </Trans>
                    </p>
                  </div>
                  <div className="col-lg-6 featured-logo">
                    <h1 className="h1-mb mt-5 mt-lg-0">{t("weFeaturedIn")}</h1>
                    <FeaturedCardContainer
                      featureData={gameData}
                      flexFill={true}
                    />
                    {/* <div className="row d-flex justify-content-center">
                    <div className="col-4">
                    <img src={gokadGayImg} alt="" className="img-fluid" />
                    </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className={
            "container-fluid " +
            (process.env.REACT_APP_PLATFORM === "nickx"
              ? " bg-orange "
              : " bg-secondary  ") +
            "news-letter"
          }
          id="news"
          ref={this.newsRef}
        >
          <div className="row">
            <div className="container">
              <div className="row">
                <div className="col-lg-7 subscrible-newsletter">
                  <h1>{t("subscribeNewsletter")}</h1>
                  <h5 className="my-4">
                    <Trans i18nKey="stayAtTop">
                      Stay at the top of your game with the latest updates,
                      <br />
                      live streaming and more.
                    </Trans>
                  </h5>
                  {this.props.subscribeForm.fields.email.error ? (
                    <span className="alert alert-danger small">
                      {this.props.subscribeForm.fields.email.errorMsg}
                    </span>
                  ) : (
                      ""
                    )}
                  {this.props.subscribeForm.fields.email.success ? (
                    <span className="alert alert-success small">
                      {this.props.subscribeForm.fields.email.successMsg}
                    </span>
                  ) : (
                      ""
                    )}
                  <form
                    className="form-inline mt-5 align-items-end"
                    onSubmit={e => {
                      e.preventDefault();
                      if (!this.props.subscribeForm.fields.email.value) {
                        this.props.subscribeFormSetError(
                          "email",
                          t("enterEmail")
                        );
                        return false;
                      }
                      if (
                        !validateEmail(
                          this.props.subscribeForm.fields.email.value
                        )
                      ) {
                        this.props.subscribeFormSetError(
                          "email",
                          t("emailInvalid")
                        );
                        return false;
                      }
                      this.props.subscribeFormSubmit({
                        email: this.props.subscribeForm.fields.email.value
                      });
                    }}
                  >
                    <AppInputComponent
                      divClass="form-group mb-2 with-lable"
                      labelText={t("labelEmailAdd")}
                      onChange={event => {
                        this.props.subscribeFormUpdateField(
                          "email",
                          event.target.value
                        );
                      }}
                      inputAddon={() => (
                        <small className="help-text">{t("neverSpam")}</small>
                      )}
                      value={this.props.subscribeForm.fields.email.value}
                    />

                    <button
                      type="submit"
                      className="btn btn-pink btn-sm btn-sm-block subscribe-alignment-adjust light mb-2"
                    >
                      Subscribe
                    </button>
                  </form>
                </div>
                <div className="col-lg-5 d-flex align-items-center justify-content-center align-items-lg-end justify-content-lg-end subscribe-img">
                  <img
                    src={
                      process.env.REACT_APP_PLATFORM === "nickx"
                        ? subscribeDevice3xImageNickx
                        : subscribeDevice3xImage
                    }
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* END Subscribe to our Newsletter */}
      </>
    );
  }
}

export default withTranslation()(HomeComponent);
