import React, { Component } from "react";
import { connect } from "react-redux";
import ProfileImage from "../../../assets/images/avatar-demo.png";
// import ProfileImage from "../../../assets/images/svg/profile-pic-120.svg";
import { ValidURL } from "../../../utils/date";

class HostTopPlayerCardViewAllComponent extends Component {
  addDefaultSrc(ev) {
    ev.target.src = ProfileImage;
  }

  render() {
    const {allOnlineUserList} = this.props;
    return (
      <div className="card popular-host text-center playerBox">
        <figure className="mr-2 mr-md-4">
          <a href={"/profile/" + this.props.id} target="_blank">
            <img
              className="profile-pic-120"
              src={
                ValidURL(this.props.avatarUrl)
                  ? this.props.avatarUrl
                  : ProfileImage
              }
              alt={this.props.name}
              onError={this.addDefaultSrc}
            />
            {allOnlineUserList.includes(this.props.id)?(<figcaption className="online-120"></figcaption>):null}
          </a>
          <figcaption className="text-secondary mt-3 brand-label">
            <a href={"/profile/" + this.props.id} className="text-orange">
              {this.props.name}
            </a>
          </figcaption>
        </figure>
      </div>
    );
  }
}

const defaultProps = {
  followerId: 1,
  avatarUrl: "Dunkin Donuts",
  name: ProfileImage
};

const mapStateToProps = state => {
  return {
    isLoggedIn: state.login.isLoggedIn,
    allOnlineUserList: state.socketusers.allOnlineUserList,
  };
};

HostTopPlayerCardViewAllComponent.defaultProps = defaultProps;

export default connect(
  mapStateToProps,
  defaultProps
)(HostTopPlayerCardViewAllComponent);
