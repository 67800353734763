import React, { Component } from "react";
import { connect } from "react-redux";
import { joinChallengePassword } from "../../../actions/modals";
import { joinChallengePasswordForm } from "../../../actions/forms";
import "./JoinChallengePasswordModal.scss";
import Modal from "../Modal";


class JoinChallengePasswordModal extends Component {
  constructor(props) {
    super(props);
    this.hideJoinChallengePassword = this.hideJoinChallengePassword.bind(this);
    this.state = {
      isLandScape: false
    };
  }

  hideJoinChallengePassword() {
    this.props.joinChallengePasswordClose();
  }
  
  render() {
    return (
      <>
        <Modal
          handleHide={this.hideJoinChallengePassword}
          isOpen={this.props.isJoinChallengePasswordOpen}
          disableOuterClick={true}
        >
          <div className="card-popup login-form">
            <div className="row">
              <div className="col-sm-12 text-center mb-2 mb-sm-4">
                <h4>Challenge Password</h4>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">

              {this.props.joinChallengePasswordForm.fields.joinChallengePasswordformStatus.error ? (
                    <h6 className="text-danger">
                      {this.props.joinChallengePasswordForm.fields.joinChallengePasswordformStatus.errorMsg}
                    </h6>
                  ) : (
                    ""
                  )}
                <form  onSubmit={e => {
                      e.preventDefault();
                      if (!this.props.joinChallengePasswordForm.fields.password.value) {
                        this.props.joinChallengePasswordFormSetError("joinChallengePasswordformStatus","Please enter password");
                        return false;
                      }
                      this.props.joinChallengePasswordFormSubmit({
                        password: this.props.joinChallengePasswordForm.fields.password.value,
                        challengeId: this.props.challengeDetail.id
                      });
                    }}
                    >
                  <div className="form-group without-lable pt-custom-1 mb-custom-1">
                    <input
                        type="password"
                        className="form-control effect-1"
                        placeholder="Enter Password"
                        onChange={event => {
                          this.props.joinChallengePasswordFormUpdateField(
                            "password",
                            event.target.value
                          );
                        }}
                        value={this.props.joinChallengePasswordForm.fields.password.value}
                      />
                    <span className="focus-border" />
                  </div>

                  <button
                    disabled={this.props.joinChallengePasswordForm.isSubmitting?true:false}
                    type="submit" className="btn btn-pink light btn-block mt-2 mt-sm-5">
                    Verify Password
                  </button>
                </form>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}
const mapDispatchToProps = {
  joinChallengePasswordOpen: joinChallengePassword.open,
  joinChallengePasswordClose:joinChallengePassword.close,
  joinChallengePasswordFormSubmit: joinChallengePasswordForm.submit,
  joinChallengePasswordFormUpdateField: joinChallengePasswordForm.updateField,
  joinChallengePasswordFormSetError: joinChallengePasswordForm.setError,
};

const mapStateToProps = state => {
  return {
    isJoinChallengePasswordOpen: state.modals.isJoinChallengePasswordOpen,
    challengeDetail : state.challenges.challengeDetails.item,
    userDetail : state.user.item,
    joinChallengePasswordForm: state.forms.joinChallengePasswordForm,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JoinChallengePasswordModal);
