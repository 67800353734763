import React, { Component } from "react";
import { connect } from "react-redux";
import "./CreateRaffleComponent.scss";
import CameraImage from "../../assets/images/svg/camera.svg";
import GameSetupOnImage from "../../assets/images/game-setup-on.png";
import TokenImage from "../../assets/images/token.png";
import Select2Component from "../Select2/Select2Component";
import { publishRaffleForm } from "../../actions/forms";
import { AllMsgClear } from "../../actions/alerts";
import AppInputComponent from "../../layouts/components/sidebars/AppInputComponent";
import { fileUpload } from "../../services/fileUpload.service";
import { APP_MESSAGES } from "../../services/config/messages";
import i18n from "../../i18n";

//datepicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class CreateRaffleComponent extends Component {
  constructor(props) {
    super(props);

    //default state
    this.state = {
      itemsChecked: true,
      pointsChecked: false,
      showUpload: true,
      startDate: undefined
    };

    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleItems = this.handleItems.bind(this);
    this.handlePoints = this.handlePoints.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.removeUpload = this.removeUpload.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.convertDateFormat = this.convertDateFormat.bind(this);
  }

  componentDidMount() {
    const lang = i18n.language;
    if (!this.props.loggedUserIsBrand) {
      window.location.href = "/" + lang + "/dashboard";
    }
  }

  convertDateFormat(date) {
    var startDateValue = "";
    var month = date.getUTCMonth() + 1; //months from 1-12
    var day = date.getUTCDate() + 1;
    var year = date.getUTCFullYear();
    startDateValue = year + "-" + month + "-" + day;

    return startDateValue;
  }

  handleChange(date) {
    this.setState({
      startDate: date
    });

    this.props.publishRaffleFormSetError("timeOfStart", "");
    this.props.publishRaffleFormUpdateField(
      "timeOfStart",
      this.convertDateFormat(date)
    );
  }

  handleFieldChange(value) {
    this.props.publishRaffleFormUpdateField("durationId", value);
  }

  handleItems() {
    this.setState({ itemsChecked: !this.state.itemsChecked });
  }

  handlePoints() {
    this.setState({ pointsChecked: !this.state.pointsChecked });
  }

  uploadFile(event) {
    if (event.target.files.length > 0) {
      const fileSize = event.target.files[0].size / 1000000;
      const fileType = event.target.files[0].type.split("/")[1].toLowerCase();

      if (fileType !== "jpeg" && fileType !== "jpg" && fileType !== "png") {
        this.props.publishRaffleFormSetError("itemUrl", "FILE_TYPE");
        return false;
      } else if (fileSize > 2) {
        this.props.publishRaffleFormSetError("itemUrl", "FILE_SIZE");
        return false;
      }
      this.props.publishRaffleFormResetError("itemUrl");
      fileUpload(event.target.files)
        .then(url => {
          this.props.publishRaffleFormUpdateField("itemUrl", url);
          this.setState({ showUpload: !this.state.showUpload });
        })
        .catch(() => {
          //console.log(this);
        });
    } else {
      this.props.publishRaffleFormUpdateField("itemUrl", "");
    }
  }

  removeUpload() {
    this.setState({ showUpload: !this.state.showUpload });
  }

  render() {
    let Error;
    let Success;

    Error = this.props.publishRaffleForm.fields.entryFee.errorMsg
      ? APP_MESSAGES[this.props.publishRaffleForm.fields.entryFee.errorMsg](
          this.props.publishRaffleForm.fields.entryFee.errorPayload,
          () => {
            this.props.publishRaffleFormResetError("entryFee");
          }
        )
      : props => <>{""}</>;

    Success = this.props.publishRaffleForm.fields.entryFee.successMsg
      ? APP_MESSAGES[this.props.publishRaffleForm.fields.entryFee.successMsg](
          this.props.publishRaffleForm.fields.entryFee.successPayload,
          () => {
            this.props.publishRaffleFormResetSuccess("entryFee");
          }
        )
      : props => <>{""}</>;

    //select at least 1 item
    if (!this.state.itemsChecked && !this.state.pointsChecked) {
      this.handleItems();
    }

    const selectOptions = [
      { id: 1, value: "Daily" },
      { id: 2, value: "Weekly" },
      { id: 3, value: "Monthly" }
    ];

    return (
      <>
        <section id="rootwizard">
          <section className="container-fluid tab-content-wrapper">
            <div className="row">
              <div className="col-sm-12">
                <div>
                  <div className="container">
                    <div className="padder-main1">
                      <div className="row">
                        <div className="col-sm-12  mt-6 mb-6">
                          <h1>Create new raffle</h1>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <Error />
                          <Success />
                        </div>
                      </div>
                      <form
                        onSubmit={e => {
                          e.preventDefault();
                          if (!this.props.publishRaffleForm.isSubmitting) {
                            //timeOfStart, HH, MM, duration, entry fee validation
                            if (
                              !this.props.publishRaffleForm.fields.timeOfStart
                                .value ||
                              !this.props.publishRaffleForm.fields.HH.value ||
                              !this.props.publishRaffleForm.fields.MM.value ||
                              !this.props.publishRaffleForm.fields.durationId
                                .value ||
                              !this.props.publishRaffleForm.fields.entryFee
                                .value
                            ) {
                              this.props.publishRaffleFormSetError(
                                "entryFee",
                                "NOT_EMPTY"
                              );
                              return false;
                            }

                            //item or points needed
                            if (
                              !this.props.publishRaffleForm.fields
                                .itemDescription.value &&
                              !this.props.publishRaffleForm.fields.points.value
                            ) {
                              this.props.publishRaffleFormSetError(
                                "entryFee",
                                "NOT_EMPTY"
                              );
                              return false;
                            }

                            var durationIdValue = "";
                            if (
                              this.props.publishRaffleForm.fields.durationId
                                .value == "Daily"
                            ) {
                              durationIdValue = 1;
                            } else if (
                              this.props.publishRaffleForm.fields.durationId
                                .value == "Weekly"
                            ) {
                              durationIdValue = 2;
                            } else {
                              durationIdValue = 3;
                            }

                            this.props.publishRaffleFormSubmit({
                              brandId: this.props.loggedUserId,
                              timeOfStart:
                                this.props.publishRaffleForm.fields.timeOfStart
                                  .value +
                                " " +
                                this.props.publishRaffleForm.fields.HH.value +
                                ":" +
                                this.props.publishRaffleForm.fields.MM.value +
                                ":00+00",
                              entryFee: this.props.publishRaffleForm.fields
                                .entryFee.value,
                              itemDescription: this.props.publishRaffleForm
                                .fields.itemDescription.value,
                              itemUrl: this.props.publishRaffleForm.fields
                                .itemUrl.value,
                              durationId: durationIdValue,
                              points: this.props.publishRaffleForm.fields.points
                                .value
                                ? this.props.publishRaffleForm.fields.points
                                    .value
                                : 0
                            });
                          }
                        }}
                        tabIndex={-1}
                      >
                        <div className="row">
                          <div className="col-sm-4 mb-4 zIndex-1">
                            <h5 className="bold">Starting @</h5>
                            <div className="form-group with-lable mt-4 ">
                              <div className="input-group-wrapper">
                                <DatePicker
                                  selected={this.state.startDate}
                                  onChange={this.handleChange}
                                  minDate={new Date()}
                                  className="form-control effect-16"
                                  placeholderText="Date"
                                  dateFormat="yyyy-MM-dd"
                                  tabIndex={1}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4 mb-4">
                            <h5 className="bold">Time</h5>
                            <div className="row">
                              <div className="col-sm-6">
                                <AppInputComponent
                                  labelText="HH"
                                  divClass="form-group with-lable mt-4"
                                  value={
                                    this.props.publishRaffleForm.fields.HH.value
                                  }
                                  onChange={event => {
                                    this.props.publishRaffleFormSetError(
                                      "HH",
                                      ""
                                    );
                                    if (event.target.value <= 59) {
                                      var HHValue = event.target.value;
                                    } else {
                                      var HHValue = 59;
                                    }
                                    this.props.publishRaffleFormUpdateField(
                                      "HH",
                                      HHValue
                                    );
                                  }}
                                  type="number"
                                  min="0"
                                  max="59"
                                  minLength="1"
                                  maxLength="2"
                                  tabIndex={-1}
                                />
                              </div>
                              <div className="col-sm-6">
                                <AppInputComponent
                                  labelText="MM"
                                  divClass="form-group with-lable mt-4"
                                  value={
                                    this.props.publishRaffleForm.fields.MM.value
                                  }
                                  onChange={event => {
                                    this.props.publishRaffleFormSetError(
                                      "MM",
                                      ""
                                    );
                                    if (event.target.value <= 59) {
                                      var MMValue = event.target.value;
                                    } else {
                                      var MMValue = 59;
                                    }
                                    this.props.publishRaffleFormUpdateField(
                                      "MM",
                                      MMValue
                                    );
                                  }}
                                  type="number"
                                  min="0"
                                  max="59"
                                  minLength="1"
                                  maxLength="2"
                                  tabIndex={-1}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4 zIndex-1 mb-4">
                            <h5 className="bold">Duration</h5>
                            <div className=" form-group with-lable mt-4">
                              <div className="input-group-wrapper">
                                <Select2Component
                                  selectOptions={selectOptions}
                                  onChange={this.handleFieldChange}
                                  selectedValue={
                                    this.props.publishRaffleForm.fields
                                      .durationId.value
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-12 mb-4">
                            <h5 className="bold">Entry fee</h5>
                            <AppInputComponent
                              labelText="Enter points"
                              divClass="form-group with-lable mt-4"
                              value={
                                this.props.publishRaffleForm.fields.entryFee
                                  .value
                              }
                              onChange={event => {
                                this.props.publishRaffleFormSetError(
                                  "entryFee",
                                  ""
                                );
                                this.props.publishRaffleFormUpdateField(
                                  "entryFee",
                                  event.target.value
                                );
                              }}
                              type="number"
                              min="0"
                              tabIndex={-1}
                            />
                          </div>
                          <div className="col-sm-12 mb-4 form-info">
                            <h5 className="bold">1 place reward</h5>
                            <ul className=" list-inline d-flex flex-row flex-wrap w-100 mt-3 mb-3">
                              <li className="list-group-control col-sm-6 col-md-3 mt-2 pl-0">
                                <div className="select-game-box">
                                  <label className="custom-control  fill-checkbox  w-100 mb-0 d-flex justify-content-center align-items-center">
                                    <div className="labelc-content d-inline-flex align-items-center">
                                      <img
                                        src={GameSetupOnImage}
                                        alt=""
                                        className="icon"
                                      />
                                      <p className="mb-0 ml-3">Items</p>
                                    </div>
                                    <div className="ml-auto">
                                      <input
                                        type="checkbox"
                                        className="fill-control-input"
                                        value=""
                                        name="firstPrize"
                                        id="firstPrize"
                                        checked={
                                          this.state.itemsChecked
                                            ? "checked"
                                            : ""
                                        }
                                      />
                                      <span
                                        className="fill-control-indicator backend"
                                        onClick={this.handleItems}
                                      />
                                    </div>
                                  </label>
                                </div>
                              </li>
                              <li className="list-group-control col-sm-6 col-md-3 mt-2 ">
                                <div className="select-game-box">
                                  <label className="custom-control  fill-checkbox  w-100 mb-0 d-flex justify-content-center align-items-center">
                                    <div className="labelc-content d-inline-flex align-items-center">
                                      <img
                                        src={TokenImage}
                                        alt=""
                                        className="icon"
                                      />
                                      <p className="mb-0 ml-3">Points</p>
                                    </div>
                                    <div className="ml-auto">
                                      <input
                                        type="checkbox"
                                        className="fill-control-input"
                                        value=""
                                        name="secondPrize"
                                        id="secondPrize"
                                        checked={
                                          this.state.pointsChecked
                                            ? "checked"
                                            : ""
                                        }
                                      />
                                      <span
                                        className="fill-control-indicator backend"
                                        onClick={this.handlePoints}
                                      />
                                    </div>
                                  </label>
                                </div>
                              </li>
                            </ul>
                          </div>
                          {this.state.itemsChecked ? (
                            <div className="col-sm-12">
                              <div className="row">
                                <div className="col-md-8">
                                  <AppInputComponent
                                    labelText="Enter item name"
                                    divClass="form-group with-lable mt-4"
                                    value={
                                      this.props.publishRaffleForm.fields
                                        .itemDescription.value
                                    }
                                    onChange={event => {
                                      this.props.publishRaffleFormSetError(
                                        "itemDescription",
                                        ""
                                      );
                                      this.props.publishRaffleFormUpdateField(
                                        "itemDescription",
                                        event.target.value
                                      );
                                    }}
                                    tabIndex={-1}
                                  />
                                </div>
                                {this.state.showUpload ? (
                                  <div className="col-md-4 upload-image d-flex">
                                    <input
                                      type="file"
                                      accept="image/*"
                                      name="img[]"
                                      className="file"
                                      onChange={this.uploadFile}
                                      ref={input => (this.inputElement = input)}
                                    />
                                    <div
                                      className="upload-button d-flex justify-content-center align-items-center"
                                      onClick={() => this.inputElement.click()}
                                    >
                                      <img
                                        src={CameraImage}
                                        className="img-fluid"
                                        alt=""
                                      />
                                      <p className="choose-photo mb-0 ml-2">
                                        Upload Image
                                      </p>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="col-md-4 upload-image d-flex">
                                    <img
                                      src={
                                        this.props.publishRaffleForm.fields
                                          .itemUrl.value
                                      }
                                      //src="https://gbg-api-dev-2.herokuapp.com/images/10126"
                                      className="profile-icon profile-pic-80"
                                      alt=""
                                    />
                                    <div
                                      className="upload-button d-flex justify-content-center align-items-center"
                                      onClick={this.removeUpload}
                                    >
                                      <a
                                        href="javascript:void(0)"
                                        className="btn btn-link text-primary"
                                      >
                                        Remove Image
                                      </a>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {this.state.pointsChecked ? (
                            <div className="col-sm-12">
                              <div className="row">
                                <div className="col-md-8">
                                  <AppInputComponent
                                    labelText="Enter points"
                                    divClass="form-group with-lable mt-4"
                                    value={
                                      this.props.publishRaffleForm.fields.points
                                        .value
                                    }
                                    onChange={event => {
                                      this.props.publishRaffleFormSetError(
                                        "points",
                                        ""
                                      );
                                      if (event.target.value <= 100000) {
                                        this.props.publishRaffleFormUpdateField(
                                          "points",
                                          event.target.value
                                        );
                                      } else {
                                        this.props.publishRaffleFormUpdateField(
                                          "points",
                                          100000
                                        );
                                      }
                                    }}
                                    min="0"
                                    max="100000"
                                    maxLength="6"
                                    type="number"
                                    tabIndex={-1}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="col-sm-12 mb-6">
                            <button
                              type="submit"
                              className="btn btn-pink py-3 btn-preview dark mw-150 mt-5 create-raffle-btn"
                            >
                              Create Raffle
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </>
    );
  }
}

const mapDispatchToProps = {
  AllMsgClear,
  publishRaffleFormSubmit: publishRaffleForm.submit,
  publishRaffleFormReset: publishRaffleForm.reset,
  publishRaffleFormResetField: publishRaffleForm.resetField,
  publishRaffleFormUpdateField: publishRaffleForm.updateField,
  publishRaffleFormSetError: publishRaffleForm.setError,
  publishRaffleFormSetSuccess: publishRaffleForm.setSuccess,
  publishRaffleFormResetError: publishRaffleForm.resetError,
  publishRaffleFormResetSuccess: publishRaffleForm.resetSuccess
};

const mapStateToProps = state => {
  return {
    publishRaffleForm: state.forms.publishRaffleForm,
    loggedUserId: state.user.item.id,
    loggedUserIsBrand: state.user.item.isBrand
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateRaffleComponent);
