import React, { Component } from "react";
import { connect } from "react-redux";
import ProfileImage from "../../../assets/images/avatar-demo.png";
import { ValidURL } from "../../../utils/date";
import i18n from "../../../i18n";

class FriendsFollowersCardComponent extends Component {
  addDefaultSrc(ev) {
    ev.target.src = ProfileImage;
  }

  render() {
    const lang = i18n.language;
    const { allOnlineUserList } = this.props;
    return (
      <div className="swiper-slide d-flex justify-content-end">
        <div className="card popular-host text-center">
          <a
            href={"/" + lang + "/profile/" + this.props.id}
            target="_blank"
            rel="noopener noreferrer"
          >
            <figure className="mr-2 mr-md-4">
              <img
                src={
                  ValidURL(this.props.avatarUrl)
                    ? this.props.avatarUrl
                    : ProfileImage
                }
                alt={this.props.name + "VV"}
                onError={this.addDefaultSrc}
                className="profile-pic-120"
              />
              {allOnlineUserList.includes(this.props.id) ? (
                <figcaption className="online-120" />
              ) : null}
              <figcaption
                className={
                  "mt-3 " +
                  (process.env.REACT_APP_PLATFORM === "nickx"
                    ? "text-orange"
                    : "text-secondary")
                }
              >
                {this.props.name}
              </figcaption>
            </figure>
          </a>
        </div>
      </div>
    );
  }
}

const defaultProps = {
  id: 1,
  avatarUrl: "Dunkin Donuts",
  name: ProfileImage
};

const mapStateToProps = state => {
  return {
    allOnlineUserList: state.socketusers.allOnlineUserList
  };
};

FriendsFollowersCardComponent.defaultProps = defaultProps;

export default connect(
  mapStateToProps,
  defaultProps
)(FriendsFollowersCardComponent);
