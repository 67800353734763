import React, { Component } from "react";
import loadinggif from "../../../assets/images/loading.gif";
import NotFoundContainer from "../../../containers/NotFoundContainer";
import DashboardQuickPlayCardComponent from "../../Dashboard/DashboardQuickPlay/DashboardQuickPlayCardComponent";

class HostChallengesQuickPlayViewAllComponent extends Component {
  componentDidMount() {
    this.props.invalidateBrandDetailsDetails();
    this.props.fetchBrandDetailsIfNeeded({
      brandId: this.props.match.params.id,
      profileId: this.props.userId
    });

    const {
      fetchHostChallengesQuickPlayViewAllIfNeeded,
      invalidateAllHostChallengesQuickPlayViewAll,
      page
    } = this.props;
    invalidateAllHostChallengesQuickPlayViewAll();
    fetchHostChallengesQuickPlayViewAllIfNeeded({
      //profileId: this.props.userId,
      brandId: this.props.match.params.id,
      page: page
    });
  }

  componentDidUpdate() {
    document.body.classList.remove("modal-open");
  }

  render() {
    const { hostChallengesQuickPlayList, brandDetails, page, history } = this.props;
    if (hostChallengesQuickPlayList.isFetching) {
      return (
        <div className="text-center">
          <div className="col-sm-12">
            <img src={loadinggif} alt="" />
          </div>
        </div>
      );
    }
    // if (!(hostChallengesQuickPlayList.items.length > 0)) {
    //   return <NotFoundContainer />;
    // }
    if (
      hostChallengesQuickPlayList &&
      brandDetails &&
      brandDetails.item &&
      hostChallengesQuickPlayList.items
    ) {
      return (
        <section className="container-fluid my-tournament-wrapper">
          <div className="row">
            <div className="container">
              <div className="padder-main">
                <div className="row d-flex align-items-center mb-30 ">
                  <div className="col-8 d-flex justify-content-start">
                    <h2 className="bold">
                      {brandDetails.item.name}'s Tournaments
                    </h2>
                  </div>
                </div>

                <div className="row card-wrapper d-flex justify-content-center justify-content-sm-start">
                  {this.props.hostChallengesQuickPlayList.items.length > 0
                    ? this.props.hostChallengesQuickPlayList.items.map(
                        (tournament, i) => (
                          <div className="col-auto mb-4" key={i}>
                            <DashboardQuickPlayCardComponent
                              tagPart1="gtmJoinQuickPlay-"
                              tagPart2={"QuickPlay" + (i + 1)}
                              {...tournament}
                              cardClass="card card-my-tournament"
                              brandAvatarClass="profile-pic-56"
                              order={i+1}
                            />
                          </div>
                        )
                      )
                    : ""}
                </div>
                <div className="row mb-30 text-center">
                  <div className="col-sm-12">
                    {this.props.hostChallengesQuickPlayList.items.length > 0 ? (
                      !this.props.hostChallengesQuickPlayList.isLastPage ? (
                        <button
                          onClick={() => {
                            this.props.invalidateHostChallengesQuickPlayViewAll();
                            this.props.fetchHostChallengesQuickPlayViewAllIfNeeded({
                              //profileId: this.props.userId,
                              brandId: this.props.match.params.id,
                              page: page
                            });
                          }}
                          className="btn btn-pink dark btn-xs"
                        >
                          Load More
                        </button>
                      ) : this.props.hostChallengesQuickPlayList.isFetching ? (
                        <img src={loadinggif} alt="" />
                      ) : (
                        ""
                      )
                    ) : this.props.hostChallengesQuickPlayList.isFetching ? (
                      <img className="text-center" src={loadinggif} alt="" />
                    ) : (
                      <span>No Tournament Found</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    } else {
      return <></>;
    }
  }
}

export default HostChallengesQuickPlayViewAllComponent;
