import React, { Component } from "react";
import { connect } from "react-redux";
import "./ChallengeComponent.scss";
import Leaderboard from "./Leaderboard";
import ChallengeBannerComponent from "./ChallengeBannerComponent";
import PlayGameCardComponent from "../Games/PlayGameCardComponent";
import loadinggif from "../../assets/images/loading.gif";
import PlayGameButton from "../../assets/images/svg/play-game.svg";
import NavArrow from "../../assets/images/svg/nav-arrow-right-blue.svg";
import DashboardNews from "../../components/Dashboard/DashboardNews/DashboardNewsComponents";
import { Link } from "react-router-dom";
import moment from "moment";
import challangeDefaultImageNickx from "../../assets/images/game-banner-nick.png";
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody
} from "react-accessible-accordion";
// 'Fancy' theme - boilerplate styles for all components, as seen on our demo:
import NotFoundContainer from "../../containers/NotFoundContainer";
import TournamentCardContainer from "../Tournaments/TournamentCardContainer";
import TournamentPoolCardContainer from "../Tournaments/TournamentPoolCardContainer";
import { challengesLeaderboardsViewAll } from "../../actions/challenges";
import adv7 from "../../assets/images/adv/adv-2.png";
import challangeDefaultImage from "../../assets/images/header-image-placeholder@3x.1.jpg";
import adv7Nickx from "../../assets/images/adv/adv-nick-1.png";
import {
  updatePlayingChallengeRequest,
  endPlayingChallengeRequest
} from "../../actions/socketusers";
import Timer from "react-compound-timer";
import Idle from "react-idle";
import { IntervalTimer } from "../../utils/date";
import ProfileMessageDropdownComponent from "../NiceDropdown/ProfileMessageDropdownComponent";
import { withTranslation, Trans } from "react-i18next";
import i18n from "../../i18n";

//Demo 4
let hidden = null;
let visibilityChange = null;
if (typeof document.hidden !== "undefined") {
  // Opera 12.10 and Firefox 18 and later support
  hidden = "hidden";
  visibilityChange = "visibilitychange";
} else if (typeof document.msHidden !== "undefined") {
  hidden = "msHidden";
  visibilityChange = "msvisibilitychange";
} else if (typeof document.webkitHidden !== "undefined") {
  hidden = "webkitHidden";
  visibilityChange = "webkitvisibilitychange";
}

class ChallengeComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      playingBtn: "start",
      lastUpdatedTime: "",
      currentTabActive: true,
      idleMode: false,
      lastFocusStatus: document.hasFocus(),
      organicTime: 0
    };

    this.getBannerCard = this.getBannerCard.bind(this);
    this.updateCall = this.updateCall.bind(this);
    this.setUpdateCall = this.setUpdateCall.bind(this);
    this.unsetUpdateCall = this.unsetUpdateCall.bind(this);
    this.setPageEvent = this.setPageEvent.bind(this);
    this.timer = null;
    this.intervalTimer = null;
    this.organicTimer = null;
    this.showChatListSidebar = this.showChatListSidebar.bind(this);
    this.changeTimerStatus = this.changeTimerStatus.bind(this);
    this.organicCall = this.organicCall.bind(this);
    this.setOrganicCall = this.setOrganicCall.bind(this);
    this.unsetOrganicCall = this.unsetOrganicCall.bind(this);
  }

  componentDidMount() {
    this.props.invalidateChallengeDetails();
    this.props.fetchChallengeDetailsIfNeeded(this.props.match.params.id);
    this.props.invalidateNewsIfNeeded();
    this.props.fetchNewsIfNeeded({
      page: 1
    });
    this.setPageEvent();

    // challengesLeaderboardsViewAll.invalidate();
    // challengesLeaderboardsViewAll.fetchIfNeeded({
    //   challengeId: 6490,
    //   page: 1
    // });
    // const {
    //   fetchChallengesLeaderboardsViewAllIfNeeded,
    //   invalidateAllChallengesLeaderboardsViewAll
    // } = this.props;
    this.props.invalidateAllChallengesLeaderboardsViewAll();
    this.props.fetchChallengesLeaderboardsViewAllIfNeeded({
      challengeId: this.props.match.params.id,
      page: 1
    });
  }
  setPageEvent() {
    if (hidden && visibilityChange) {
      // Standards:
      document.addEventListener(visibilityChange, this.handleVisibilityChange);
    }
    //  else if ("onfocusin" in document) {
    //   // IE 9 and lower:
    //   document.onfocusin = document.onfocusout = this.handleVisibilityChange;
    // }
    // window.onpageshow = window.onpagehide
    //   = window.onfocus = window.onblur = this.handleVisibilityChange;
    // window.onpageshow = window.onpagehide
    //   = window.onfocus = window.onblur = this.handleVisibilityChange1;
  }
  componentWillUnmount() {
    document.removeEventListener(visibilityChange, this.handleVisibilityChange);
  }
  showChatListSidebar(profileDetail) {
    this.props.chatListSidebarOpen();
    this.props.openChatHistory(profileDetail);
  }

  handleVisibilityChange = evt => {
    let v = "visible",
      h = "hidden",
      evtMap = {
        focus: v,
        focusin: v,
        pageshow: v,
        blur: h,
        focusout: h,
        pagehide: h
      };

    evt = evt || window.event;
    let eventType = "";
    if (evt.type in evtMap) eventType = evtMap[evt.type];
    else eventType = document[hidden] ? "hidden" : "visible";
    // if (this.state.playingBtn == "end") {
    //   if (eventType === "hidden") {
    //     this.timer && this.timer.pause();
    //     this.intervalTimer && this.intervalTimer.pause();
    //     this.setState({ currentTabActive: false });
    //   } else {
    //     this.timer && this.timer.resume();
    //     this.intervalTimer && this.intervalTimer.resume();
    //     this.setState({ currentTabActive: true });
    //   }
    // }
  };
  handleVisibilityChange1 = evt => {
    // console.log("event", evt);
  };

  updateCall() {
    //if (this.state.currentTabActive && !this.state.idleMode) {
    this.setState({ lastUpdatedTime: Date.now() });
    this.props.updatePlayingChallengeRequest(
      this.props.challengeDetails.item.id,
      this.props.user.id
    );
    //}
  }

  setUpdateCall() {
    this.intervalTimer = new IntervalTimer(this.updateCall, 60000);
  }

  unsetUpdateCall() {
    // this.intervalTimer && clearInterval(this.intervalTimer);
    this.intervalTimer.stop();
    this.intervalTimer = null;
  }

  organicCall() {
    this.setState({ organicTime: this.state.organicTime + 1 });
  }

  setOrganicCall() {
    this.organicTimer = new IntervalTimer(this.organicCall, 1000);
  }

  unsetOrganicCall() {
    if (this.organicTimer) {
      this.organicTimer.stop();
      this.organicTimer = null;
    }
  }

  changeTimerStatus(timerStatus) {
    if (this.state.playingBtn == "end") {
      if (timerStatus == "left") {
        this.timer && this.timer.pause();
        this.setOrganicCall();
      }
      if (timerStatus == "entered") {
        this.timer && this.timer.resume();
        this.unsetOrganicCall();
      }
    }
  }

  addDefaultSrc(ev) {
    ev.target.src =
      process.env.REACT_APP_PLATFORM === "nickx"
        ? challangeDefaultImageNickx
        : challangeDefaultImage;
  }

  getBannerCard(buttonShow) {
    const { challengeImageUrl } = this.props.challengeDetails.item;
    return (
      <div className="game-screen">
        <img
          src={challengeImageUrl}
          className="game-banner"
          alt=""
          onError={this.addDefaultSrc}
        />
        {buttonShow ? (
          <span
            className="a ply-btn"
            onClick={e => {
              e.preventDefault();
              this.props.joinChallengePasswordFormReset();
              this.props.quickChallengeJoinOpen();
            }}
          >
            {" "}
            <img src={PlayGameButton} alt="" />
          </span>
        ) : (
          ""
        )}
      </div>
    );
  }

  render() {
    const lang = i18n.language;
    if (this.props.challengeDetails.isFetching) {
      return (
        <div className="text-center">
          <div className="col-sm-12">
            <img src={loadinggif} alt="" />
          </div>
        </div>
      );
    }

    if (!this.props.challengeDetails.item) {
      return <NotFoundContainer />;
    }

    const {
      challengeDetails,
      user,
      newsListAll,
      allOnlineUserList,
      t
    } = this.props;

    const {
      packageId,
      timeOfEnd,
      challengeStatusId,
      hasAlreadyJoined,
      rules,
      brandAvatar,
      brandName,
      brandId,
      challengeTitle,
      organizerId,
      about,
      aboutImage
    } = challengeDetails.item;

    let containerOncondition = "";
    if (
      this.props.challengeDetails.item.winCriteria &&
      this.props.challengeDetails.item.winCriteria.id != 4
    ) {
      if (
        challengeStatusId == "3" &&
        hasAlreadyJoined === true &&
        // (isSubscribed === true || subscriptionTypeId == "0") &&
        -1 * moment().diff(timeOfEnd, "minutes") >= 1
      ) {
        containerOncondition = (
          <PlayGameCardComponent
            gameId={packageId}
            changeTimer={this.changeTimerStatus}
          />
        );
      } else {
        if (
          challengeStatusId == "2" &&
          hasAlreadyJoined === false &&
          -1 * moment().diff(timeOfEnd, "minutes") >= 1
        ) {
          containerOncondition = this.getBannerCard(true);
        } else {
          containerOncondition = this.getBannerCard(false);
        }
      }
    }
    if (
      this.props.challengeDetails.item.winCriteria &&
      this.props.challengeDetails.item.winCriteria.id == 4
    ) {
      if (
        challengeStatusId == "3" &&
        hasAlreadyJoined === true &&
        // (isSubscribed === true || subscriptionTypeId == "0") &&
        -1 * moment().diff(timeOfEnd, "minutes") >= 1 &&
        this.state.playingBtn == "end"
      ) {
        containerOncondition = (
          <PlayGameCardComponent
            gameId={packageId}
            changeTimer={this.changeTimerStatus}
          />
        );
      } else {
        if (
          challengeStatusId == "2" &&
          hasAlreadyJoined === false &&
          -1 * moment().diff(timeOfEnd, "minutes") >= 1
        ) {
          containerOncondition = this.getBannerCard(true);
        } else {
          containerOncondition = this.getBannerCard(false);
        }
      }
    }
    //console.log(this.props.challengeDetails.item.id, 'challengeId')
    //console.log(user.id, 'userId')

    return (
      <div>
        <ChallengeBannerComponent
          challengeDetails={challengeDetails.item}
          user={user}
          t={t}
        />
        <div className="container-fluid todays-challange">
          <div className="padder-main">
            <div className="row">
              <div className="container">
                <div className="row d-flex justify-content-center">
                  <div
                    className="col-lg-12 col-xl-9 text-center"
                    style={{ zIndex: 9 }}
                  >
                    <h2 className="mb-30 bold">
                      {t("challengePage.todayschallenge")}
                    </h2>
                    <h5 className="challange-content mb-5-half">
                      <Trans i18nKey="challengePage.takePart">
                        Take part in today’s challenge for a chance to earn gold
                        tokens and <br /> win great prizes daily, weekly and
                        monthly:{" "}
                      </Trans>
                    </h5>
                    {containerOncondition}
                    {this.props.challengeDetails.item.winCriteria &&
                    this.props.challengeDetails.item.winCriteria.id == 4 &&
                    challengeStatusId == 3 &&
                    hasAlreadyJoined == true ? (
                      <div>
                        <br />
                        {/* <Idle
                          timeout={30000}
                          render={({ idle }) =>
                            // <p className={idle ? "idle" : "not-idle"}>
                            //   {idle
                            //     ? "You are idle."
                            //     : "Stop doing stuff for 30 seconds."
                            //   }
                            // </p>
                            ""
                          }
                          onChange={({ idle }) => {
                            if (this.state.playingBtn == "end") {
                              if (idle) {
                                this.setState({ idleMode: true });
                                this.timer && this.timer.pause();
                                this.intervalTimer &&
                                  this.intervalTimer.pause();
                                //console.log('idle');
                              } else {
                                this.setState({ idleMode: false });
                                this.timer && this.timer.resume();
                                this.intervalTimer &&
                                  this.intervalTimer.resume();
                                //console.log('not idle');
                              }
                            }
                          }}
                        /> */}
 <Timer
                          startImmediately={false}
                          ref={ele => (this.timer = ele)}
                        >
                          {({
                            start,
                            resume,
                            pause,
                            stop,
                            reset,
                            timerState
                          }) => (
                            <React.Fragment>
                              <div
                                className={
                                  " bold " +
                                  (process.env.REACT_APP_PLATFORM === "nickx"
                                    ? "text-orange"
                                    : "text-secondary")
                                }
                              >
                                <Timer.Hours
                                  formatValue={value =>
                                    `${value < 10 ? `0${value}` : value}`
                                  }
                                />
                                :
                                <Timer.Minutes
                                  formatValue={value =>
                                    `${value < 10 ? `0${value}` : value}`
                                  }
                                />
                                :
                                <Timer.Seconds
                                  formatValue={value =>
                                    `${value < 10 ? `0${value}` : value}`
                                  }
                                />
                              </div>
                            </React.Fragment>
                          )}
                        </Timer>
                        <br />
                        {this.state.playingBtn == "start" ? (
                          <button
                            className="btn btn-pink dark btn-lg"
                            onClick={e => {
                              e.preventDefault();
                              this.setState({
                                playingBtn: "end",
                                lastUpdatedTime: Date.now(),
                                organicTime: 0
                              });
                              this.timer && this.timer.start();
                              this.setUpdateCall();
                            }}
                          >
                            {t("challengePage.startGame")}
                          </button>
                        ) : (
                          ""
                        )}
                        {this.state.playingBtn == "end" ? (
                          <button
                            className="btn btn-pink dark btn-lg"
                            onClick={e => {
                              e.preventDefault();
                              this.setState({ playingBtn: "start" });
                              //console.log('time ', this.timer.getTime());
                              let endSeconds =
                                (Date.now() -
                                  this.state.lastUpdatedTime -
                                  this.state.organicTime * 1000) /
                                1000;
                              if (this.timer) {
                                this.timer.reset();
                                this.timer.stop();
                              }
                              this.unsetUpdateCall();
                              this.setState({ lastUpdatedTime: "" });
                              this.props.endPlayingChallengeRequest(
                                this.props.challengeDetails.item.id,
                                user.id,
                                endSeconds,
                                endSeconds > this.state.organicTime
                                  ? this.state.organicTime
                                  : 0
                              );

                              this.props.invalidateChallengeDetails();
                              this.props.fetchChallengeDetailsIfNeeded(
                                this.props.match.params.id
                              );
                            }}
                          >
                            {t("challengePage.endGame")}
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="container-fluid prize-pull section-border-top">
          <div className="row">
            <div className="container">
              <div className="row d-flex align-items-center mb-30 ">
                <div className="col-12 text-center d-flex justify-content-center">
                  <h2 className="heading bold">
                    {
                      challengeDetails.item &&
                      challengeDetails.item.challengePlacementRewards &&
                      challengeDetails.item.challengePlacementRewards.length
                        ? t("challengePage.prizePool")
                        : ""
                    }
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="row livetournament">
            <TournamentPoolCardContainer
              list={challengeDetails.item.challengePlacementRewards}
              showAd={process.env.REACT_APP_PLATFORM !== "nickx" ? false : false}
            />
          </div>
          {/* {process.env.REACT_APP_PLATFORM !== "nickx" ? (
            <div className="container">
              <div className="row">
                <div className="col-sm-12 d-flex justify-content-center ">
                  <div
                    className="banner-advertising sm d-none d-lg-block mt-6"
                    id="adv-2"
                  >
                    <img
                      src={
                        process.env.REACT_APP_PLATFORM === "nickx"
                          ? adv7Nickx
                          : adv7
                      }
                      alt=""
                      className="d-none d-lg-block"
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            false
          )} */}
        </section>

        <Leaderboard
          type="challenge"
          userData={this.props.user}
          data={challengeDetails.item}
          // leaderboard={[
          //   {
          //     id: 4644,
          //     points: 2579,
          //     profileId: 4053,
          //     profileName: "dummy1713",
          //     profileAvatarUrl: null
          //   },
          //   {
          //     id: 4644,
          //     points: 2579,
          //     profileId: 4053,
          //     profileName: "dummy1713",
          //     profileAvatarUrl: null
          //   },
          //   {
          //     id: 4644,
          //     points: 2579,
          //     profileId: 4053,
          //     profileName: "dummy1713",
          //     profileAvatarUrl: null
          //   },
          //   {
          //     id: 4644,
          //     points: 2579,
          //     profileId: 4053,
          //     profileName: "dummy1713",
          //     profileAvatarUrl: null
          //   },
          //   {
          //     id: 4644,
          //     points: 2579,
          //     profileId: 4053,
          //     profileName: "dummy1713",
          //     profileAvatarUrl: null
          //   },
          //   {
          //     id: 4644,
          //     points: 2579,
          //     profileId: 4053,
          //     profileName: "dummy1713",
          //     profileAvatarUrl: null
          //   },
          //   {
          //     id: 4644,
          //     points: 2579,
          //     profileId: 4053,
          //     profileName: "dummy1713",
          //     profileAvatarUrl: null
          //   },
          //   {
          //     id: 4644,
          //     points: 2579,
          //     profileId: 4053,
          //     profileName: "dummy1713",
          //     profileAvatarUrl: null
          //   },
          //   {
          //     id: 4644,
          //     points: 2579,
          //     profileId: 4053,
          //     profileName: "dummy1713",
          //     profileAvatarUrl: null
          //   },
          //   {
          //     id: 4644,
          //     points: 2579,
          //     profileId: 4053,
          //     profileName: "dummy1713",
          //     profileAvatarUrl: null
          //   },
          //   {
          //     id: 4644,
          //     points: 2579,
          //     profileId: 4053,
          //     profileName: "dummy1713",
          //     profileAvatarUrl: null
          //   },
          //   {
          //     id: 4644,
          //     points: 2579,
          //     profileId: 4053,
          //     profileName: "dummy474747474",
          //     profileAvatarUrl: null
          //   },
          //   {
          //     id: 4644,
          //     points: 2579,
          //     profileId: 4053,
          //     profileName: "dummy85858585",
          //     profileAvatarUrl: null
          //   },
          // ]}
          currentUserLeaderBoard={challengeDetails.item.currentUserLeaderBoard}
          t={t}
        />

        <section className="container-fluid about-mtn-treasure section-border-top">
          <div className="padder-main">
            <div className="row">
              <div className="container">
                <div className="row d-flex align-items-center justify-content-center">
                  <div className="col-lg-12 col-xl-9 text-center">
                    {about || aboutImage ? (
                      <h2 className="mb-30 bold">About the {challengeTitle}</h2>
                    ) : (
                      ""
                    )}
                    {about ? <p className="mt-4">{about}</p> : ""}

                    {aboutImage ? (
                      <img src={aboutImage} className="w-100" alt="" />
                    ) : (
                      ""
                    )}
                    <div className="tournament-accordian mt-5">
                      <Accordion>
                        <AccordionItem className="card bb-1">
                          <AccordionItemTitle className="card-header">
                            <h4 className="semi-bold text-left u-position-relative">
                              {t("challengePage.rules")}
                              <div
                                className="accordion__arrow"
                                role="presentation"
                              />
                            </h4>
                          </AccordionItemTitle>
                          <AccordionItemBody>
                            <div className="card-body text-left">
                              <div
                                dangerouslySetInnerHTML={{ __html: rules }}
                              />
                            </div>
                          </AccordionItemBody>
                        </AccordionItem>

                        <AccordionItem className="card">
                          <AccordionItemTitle className="card-header">
                            <h4 className="semi-bold text-left u-position-relative">
                              {t("challengePage.host")}
                              <div
                                className="accordion__arrow"
                                role="presentation"
                              />
                            </h4>
                          </AccordionItemTitle>
                          <AccordionItemBody>
                            <div className="card-body text-left">
                              <div className="d-flex align-items-center">
                                <figure>
                                  <a
                                    href={"/" + lang + "/host/" + brandId}
                                    target="_blank"
                                  >
                                    <img
                                      src={brandAvatar}
                                      className="profile-pic-56"
                                      alt=""
                                    />
                                    {allOnlineUserList.includes(organizerId) ? (
                                      <figcaption className="online-56" />
                                    ) : null}
                                  </a>
                                </figure>
                                <p className="bold ml-3 text-truncate">
                                  <a
                                    href={"/" + lang + "/host/" + brandId}
                                    target="_blank"
                                  >
                                    {brandName}
                                  </a>
                                </p>
                                {process.env.REACT_APP_PLATFORM === "nickx" ? (
                                  challengeDetails.item &&
                                  challengeDetails.item.brand &&
                                  challengeDetails.item.brand.profile &&
                                  challengeDetails.item.brand.profile.length ? (
                                    challengeDetails.item.brand.profile
                                      .length == 1 ? (
                                      <span
                                        className={
                                          "btn rounded-xs ml-5 w-100 maxw-180 ml-3 " +
                                          (process.env.REACT_APP_PLATFORM ===
                                          "nickx"
                                            ? "btn-orange "
                                            : "btn-secondary")
                                        }
                                        onClick={e => {
                                          this.showChatListSidebar({
                                            id:
                                              challengeDetails.item.brand
                                                .profile[0].id,
                                            userName:
                                              challengeDetails.item.brand
                                                .profile[0].name,
                                            profileUrl:
                                              challengeDetails.item.brand
                                                .profile[0].avatarUrl
                                          });
                                        }}
                                      >
                                        Message
                                      </span>
                                    ) : (
                                      <div className="ml-5">
                                        <ProfileMessageDropdownComponent
                                          profiles={
                                            challengeDetails.item.brand.profile
                                          }
                                        />
                                      </div>
                                    )
                                  ) : (
                                    <></>
                                  )
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </AccordionItemBody>
                        </AccordionItem>
                      </Accordion>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="container-fluid upcoming-tournament section-border-top">
          <div className="padder-main">
            <div className="row">
              <div className="container">
                <div className="row d-flex align-items-center">
                  <div className="col-8 d-flex justify-content-start">
                    <h2 className="mb-5-half bold">
                      {t("challengePage.moreBy")} {brandName}
                    </h2>
                  </div>

                  {this.props.challengesByBrand.items &&
                  this.props.challengesByBrand.items.length > 0 ? (
                    <div className="col text-right d-flex justify-content-end">
                      <Link
                        to={
                          "/" +
                          lang +
                          "/challenges/brand/" +
                          brandId +
                          "/" +
                          brandName
                        }
                        className={
                          "view-all d-flex align-items-center mb-5-half"
                        }
                      >
                        {t("viewAll")}{" "}
                        <span>
                          <img src={NavArrow} alt="" />
                        </span>
                      </Link>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="row livetournament">
              <TournamentCardContainer
                list={this.props.challengesByBrand.items.map(
                  (challenge, index) => {
                    return {
                      ...challenge,
                      cardClass: "card popular-arcadex",
                      cardHeaderClass: "card-header light"
                    };
                  }
                )}
                showAd={
                  process.env.REACT_APP_PLATFORM !== "nickx" ? false : false
                }
                challengeType="Brand"
              />
            </div>
          </div>
        </section>
        <section className="container-fluid news section-border-top section-border-top">
          <div className="padder-main">
            <div className="row">
              <div className="container">
                <div className="row d-flex align-items-center">
                  <div className="col-8 d-flex justify-content-start">
                    <h2 className="mb-5-half bold">{t("navNews")}</h2>
                  </div>

                  {newsListAll && newsListAll.length > 0 ? (
                    <div className="col text-right d-flex justify-content-end">
                      <Link
                        to={"/" + lang + "/news"}
                        className={
                          "view-all d-flex align-items-center mb-5-half"
                        }
                      >
                        {t("viewAll")}{" "}
                        <span>
                          <img src={NavArrow} alt="" />
                        </span>
                      </Link>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            <div className="row livetournament">
              {newsListAll && newsListAll.length > 0 ? (
                <DashboardNews list={newsListAll} />
              ) : (
                <div className="col">
                  <h6 className="text-center">{t("noNewsFound")}</h6>
                </div>
              )}
            </div>
          </div>
        </section>
        <div
          className={
            this.props.finishGameChallengeForm.isSubmitting === true ||
            this.props.challengeDetails.isFetching === true
              ? "ui-blocker"
              : "d-none"
          }
        />
      </div>
    );
  }
}

//export default ChallengeComponent;

const mapDispatchToProps = {
  updatePlayingChallengeRequest: updatePlayingChallengeRequest,
  endPlayingChallengeRequest: endPlayingChallengeRequest,

  fetchChallengesLeaderboardsViewAllIfNeeded:
    challengesLeaderboardsViewAll.fetchIfNeeded,
  invalidateChallengesLeaderboardsViewAll:
    challengesLeaderboardsViewAll.invalidate,
  invalidateAllChallengesLeaderboardsViewAll:
    challengesLeaderboardsViewAll.invalidateAll
};

const mapStateToProps = state => {

  return {
    currentLang: state.login.currentLang,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ChallengeComponent));
