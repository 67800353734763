import React, { Component } from "react";
import "./FriendsProfileComponent.scss";
import AvatarDemo from "../../assets/images/avatar-demo.png";
import FbContainerBlack from "../../assets/images/svg/fb-container-black.svg";
import TwitContainerBlack from "../../assets/images/svg/twitter-container-black.svg";
import InstaContainerBlack from "../../assets/images/svg/instagram-container-black.svg";
import gameController3x from "../../assets/images/game-controller@3x.png";
import trophy3x from "../../assets/images/trophy@3x.png";
import prize3x from "../../assets/images/prize@3x.png";
import gameControllerSvg from "../../assets/images/svg/game-controller.svg";
import trophySvg from "../../assets/images/svg/trophy.svg";
import prizeSvg from "../../assets/images/svg/prize.svg";
import navRightArrow from "../../assets/images/svg/nav-arrow-right-blue.svg";
import FriendsFollowersComponent from "./FriendsFollowers/FriendsFollowersComponent";
import FriendsFollowingsComponent from "./FriendsFollowers/FriendsFollowingsComponent";
import TournamentCardContainer from "../Tournaments/TournamentCardContainer";
import loadinggif from "../../assets/images/loading.gif";
import NotFoundContainer from "../../containers/NotFoundContainer";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";

class FriendsProfileComponent extends Component {
  constructor(props) {
    super(props);
    this.Capitalize = this.Capitalize.bind();
    this.showChatListSidebar = this.showChatListSidebar.bind(this);
  }
  Capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  showChatListSidebar(profileDetail) {
    this.props.chatListSidebarOpen();
    this.props.openChatHistory(profileDetail);
  }

  componentDidMount() {
    this.props.invalidateFriendsProfileDetails();
    this.props.fetchFriendsProfileIfNeeded({
      profileId: this.props.match.params.id
    });

    this.props.invalidateFriendsFollowersDetails();
    this.props.fetchFriendsFollowersIfNeeded({
      profileId: this.props.match.params.id,
      onlyId: false
    });

    this.props.invalidateFriendsFollowingsDetails();
    this.props.fetchFriendsFollowingsIfNeeded({
      profileId: this.props.match.params.id,
      onlyId: false
    });
    // this.props.invalidateFriendsTournamentsDetails();
    // this.props.fetchFriendsTournamentsIfNeeded({
    //   page: ""
    // });

    const {
      fetchFriendsTournamentsViewAllIfNeeded,
      invalidateAllFriendsTournamentsViewAll,
      page
    } = this.props;
    invalidateAllFriendsTournamentsViewAll();
    fetchFriendsTournamentsViewAllIfNeeded({
      profileId: this.props.match.params.id,
      page: 1
    });
  }
  render() {
    const {
      profile,
      friendsFollowersList,
      friendsTournamentsList,
      friendsFollowingsList,
      isFetching,
      allOnlineUserList,
      t
    } = this.props;
    const lang = i18n.language;
    if (isFetching) {
      return (
        <div className="text-center">
          <div className="col-sm-12">
            <img src={loadinggif} alt="" />
          </div>
        </div>
      );
    }
    if (profile && !profile.id) {
      return <NotFoundContainer />;
    }

    if (profile && profile.id) {
      const { items } = friendsFollowersList;

      let CheckfollowerOrNOt;
      if (items) {
        CheckfollowerOrNOt = items
          .filter(data => data.id === this.props.userId)
          .map(item => {
            return item;
          });
      } else {
        CheckfollowerOrNOt = 0;
      }
      let envClass =
        process.env.REACT_APP_PLATFORM === "nickx"
          ? "text-orange"
          : "text-secondary";
      return (
        <>
          <section className="container-fluid banner-wrapper">
            <div className="banner friend-profile">
              <div className="row ">
                <div className="container">
                  <div className="padder">
                    <div className="row justify-content-center">
                      <div className="col-lg-12 col-xl-11">
                        <div className="row d-flex align-items-center justify-content-center">
                          <div className="col-12 col-md-auto text-center profileBox">
                            <figure>
                              <img
                                src={
                                  profile.avatar_url
                                    ? profile.avatar_url
                                    : AvatarDemo
                                }
                                className="profile-pic-160 bg-white"
                                alt=""
                              />
                              {allOnlineUserList.includes(profile.id) ? (
                                <figcaption className="online-160" />
                              ) : null}
                            </figure>
                          </div>
                          <div className="col text-center text-md-left">
                            <h1 className="mb-0 mt-4 display-3 bold name  mt-md-0 mb-md-4">
                              {this.Capitalize(profile.name)}
                            </h1>
                            <div className="row">
                              <div className="col-12 col-md d-flex flex-column  flex-md-row align-items-center">
                                <h6 className="my-3 my-md-0">
                                  <span className="bold">
                                    {profile.followerCount}
                                  </span>
                                  {profile.followerCount > 1
                                    ? t("followers")
                                    : t("follower")}
                                  <span className="bold ml-2">
                                    {profile.followeeCount}
                                  </span>
                                  {profile.followeeCount > 1
                                    ? t("followings")
                                    : t("following")}
                                </h6>
                                <ul className="list-inline mb-0 d-flex ml-3">
                                  {profile.socialFacebook ? (
                                    <li className="list-inline-item">
                                      <a href={profile.socialFacebook}>
                                        <img src={FbContainerBlack} alt="" />
                                      </a>
                                    </li>
                                  ) : (
                                    ""
                                  )}
                                  {profile.socialTwitter ? (
                                    <li className="list-inline-item">
                                      <a href={profile.socialTwitter}>
                                        <img src={TwitContainerBlack} alt="" />{" "}
                                      </a>
                                    </li>
                                  ) : (
                                    ""
                                  )}
                                  {profile.socialInstagram ? (
                                    <li className="list-inline-item">
                                      <a href={profile.socialInstagram}>
                                        <img src={InstaContainerBlack} alt="" />{" "}
                                      </a>
                                    </li>
                                  ) : (
                                    ""
                                  )}
                                </ul>
                              </div>
                              <div className="col-12 col-md d-flex align-items-center justify-content-center justify-content-lg-end mt-4 mt-md-0">
                                {this.props.userId !==
                                parseInt(this.props.match.params.id) ? (
                                  CheckfollowerOrNOt.length > 0 ? (
                                    <span
                                      className={
                                        "btn rounded-xs w-100 maxw-180 " +
                                        (process.env.REACT_APP_PLATFORM ===
                                        "nickx"
                                          ? "btn-outline-orange "
                                          : "btn-outline-secondary")
                                      }
                                      onClick={() => {
                                        this.props.unfollowFormSubmit({
                                          followeeId: this.props.match.params.id
                                        });
                                      }}
                                    >
                                      {t("unfollow")}
                                    </span>
                                  ) : (
                                    <span
                                      className={
                                        "btn rounded-xs w-100 maxw-180 " +
                                        (process.env.REACT_APP_PLATFORM ===
                                        "nickx"
                                          ? "btn-outline-orange "
                                          : "btn-outline-secondary")
                                      }
                                      onClick={() => {
                                        this.props.followFormSubmit({
                                          followeeId: this.props.match.params.id
                                        });
                                      }}
                                    >
                                      {t("follow")}
                                    </span>
                                  )
                                ) : (
                                  ""
                                )}
                                {process.env.REACT_APP_PLATFORM === "nickx" ? (
                                  ""
                                ) : this.props.userId !==
                                  parseInt(this.props.match.params.id) ? (
                                  <span
                                    className={
                                      "btn rounded-xs ml-2 w-100 maxw-180 " +
                                      (process.env.REACT_APP_PLATFORM ===
                                      "nickx"
                                        ? "btn-orange "
                                        : "btn-secondary")
                                    }
                                    onClick={e => {
                                      this.showChatListSidebar({
                                        id: profile.id,
                                        userName: profile.name,
                                        profileUrl: profile.avatar_url
                                      });
                                    }}
                                  >
                                    Message
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row justify-content-center mt-6 profile-info">
                          <div className="col-6 col-lg d-flex flex-column justify-content-center align-items-center br-light mb-4 mb-lg-0">
                            <img
                              src={gameController3x}
                              alt=""
                              className="d-none d-lg-block"
                            />
                            <img
                              src={gameControllerSvg}
                              alt=""
                              className="d-block d-lg-none svg"
                            />
                            <h6
                              className={"semi-bold mt-1 mt-lg-3 " + envClass}
                            >
                              {t("challengePage.gamePlayed")}
                            </h6>
                            <h2 className="bold mt-2">{profile.gamesPlayed}</h2>
                          </div>
                          <div className="col-6 col-lg d-flex flex-column justify-content-center align-items-center br-light mb-4 mb-lg-0">
                            <img
                              src={trophy3x}
                              alt=""
                              className="d-none d-lg-block"
                            />
                            <img
                              src={trophySvg}
                              alt=""
                              className="d-block d-lg-none svg"
                            />
                            <h6
                              className={"semi-bold mt-1 mt-lg-3 " + envClass}
                            >
                              {t("navTournaments")}
                            </h6>
                            <h2 className="bold mt-2">
                              {profile.tournamentsCount}
                            </h2>
                          </div>
                          <div className="col-6 col-lg d-flex flex-column justify-content-center align-items-center ">
                            <img
                              src={prize3x}
                              alt=""
                              className="d-none d-lg-block"
                            />
                            <img
                              src={prizeSvg}
                              alt=""
                              className="d-block d-lg-none svg"
                            />
                            <h6
                              className={"semi-bold mt-1 mt-lg-3 " + envClass}
                            >
                              {t("prizeWon")}
                            </h6>
                            <h2 className="bold mt-2">{profile.prizesWon}</h2>
                          </div>
                          {/* <div className="col-6 col-lg d-flex flex-column justify-content-center align-items-center ">
                            <img
                              src={crown3X}
                              alt=""
                              className="d-none d-lg-block"
                            />
                            <img
                              src={crownSvg}
                              alt=""
                              className="d-block d-lg-none svg"
                            />
                            <h6 className="text-secondary semi-bold  mt-1 mt-lg-3">
                              Overall Ranking
                            </h6>
                            <h2 className="bold mt-2">
                              {profile.stats.overallRank}
                              <small className="bold">
                                <sup>th</sup>
                              </small>
                            </h2>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={
                  "curve-bg " +
                  (process.env.REACT_APP_PLATFORM === "nickx" ? "nickx" : "")
                }
              >
                <svg
                  version="1.1"
                  x="0px"
                  y="0px"
                  width="100%"
                  height="100%"
                  viewBox="0 0 372 440.3"
                  enableBackground="new 0 0 372 440.3"
                  xmlSpace="preserve"
                >
                  <defs />
                  <polygon points="248,0 372,185 0,440.3 0,0.3 " />
                </svg>
              </div>
            </div>
          </section>
          <section className="[ container-fluid ] live-tournament ">
            <div className="padder-main">
              <div className="row">
                <div className="container">
                  <div className="row d-flex align-items-center mb-30 ">
                    <div className="col col-sm-6 col-md-10 d-flex justify-content-start">
                      <h2 className="bold">
                        {this.Capitalize(profile.name) + "'s"}{" "}
                        {t("navTournaments")}
                      </h2>
                    </div>
                    <div className="col text-right d-flex justify-content-end">
                      {friendsTournamentsList.items &&
                      friendsTournamentsList.items.length > 0 ? (
                        <a
                          href={
                            "/" +
                            lang +
                            "/profile/" +
                            this.props.match.params.id +
                            "/challenges"
                          }
                          className="view-all d-flex align-items-center"
                          target="_blank"
                        >
                          {t("viewAll")}{" "}
                          <span>
                            <img src={navRightArrow} alt="" />
                          </span>
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row livetournament">
                {friendsTournamentsList.items &&
                friendsTournamentsList.items.length > 0 ? (
                  <TournamentCardContainer
                    list={
                      friendsTournamentsList.items
                        ? friendsTournamentsList.items.map((ele, inde) => {
                            return {
                              ...ele,
                              cardClass: "card popular-arcadex",
                              cardHeaderClass: "card-header light"
                            };
                          })
                        : []
                    }
                  />
                ) : (
                  <div className="col">
                    <h6 className="text-center">{t("noTournamentFound")}</h6>
                  </div>
                )}
              </div>
            </div>
          </section>
          <section className="[ container-fluid ] popular-host section-border-top">
            <div className="padder-main">
              <div className="row">
                <div className="container">
                  <div className="row d-flex align-items-center mb-30 ">
                    <div className="col col-sm-6 col-md-10 d-flex justify-content-start">
                      <h2 className="bold">
                        {this.Capitalize(profile.name) + "'s"}
                        {t("followers")}
                      </h2>
                    </div>
                    <div className="col text-right d-flex justify-content-end">
                      {items && items.length > 0 ? (
                        <a
                          href={
                            "/" +
                            lang +
                            "/profile/" +
                            this.props.match.params.id +
                            "/followers"
                          }
                          className="a view-all d-flex align-items-center"
                          target="_blank"
                        >
                          {t("viewAll")}{" "}
                          <span>
                            <img src={navRightArrow} alt="" />
                          </span>
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row livetournament">
                <div className="swiper-container swiper-popular-host">
                  <div className="swiper-wrapper">
                    {items && items.length > 0 ? (
                      <FriendsFollowersComponent list={items} />
                    ) : (
                      <div className="col">
                        <h6 className="text-center">{t("noFollowersFound")}</h6>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="[ container-fluid ] popular-host section-border-top">
            <div className="padder-main">
              <div className="row">
                <div className="container">
                  <div className="row d-flex align-items-center mb-30 ">
                    <div className="col col-sm-6 col-md-10 d-flex justify-content-start">
                      <h2 className="bold">
                        {this.Capitalize(profile.name) + "'s"}
                        {t("followings")}
                      </h2>
                    </div>
                    <div className="col text-right d-flex justify-content-end">
                      {friendsFollowingsList &&
                      friendsFollowingsList.items &&
                      friendsFollowingsList.items.length > 0 ? (
                        <a
                          href={
                            "/" +
                            lang +
                            "/profile/" +
                            this.props.match.params.id +
                            "/followings"
                          }
                          className="a view-all d-flex align-items-center"
                          target="_blank"
                        >
                          {t("viewAll")}{" "}
                          <span>
                            <img src={navRightArrow} alt="" />
                          </span>
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row livetournament">
                <div className="swiper-container swiper-popular-host">
                  <div className="swiper-wrapper">
                    {friendsFollowingsList &&
                    friendsFollowingsList.items &&
                    friendsFollowingsList.items.length > 0 ? (
                      <FriendsFollowingsComponent
                        list={friendsFollowingsList.items}
                      />
                    ) : (
                      <div className="col">
                        <h6 className="text-center">
                          {t("noFollowingsFound")}
                        </h6>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      );
    } else {
      return <></>;
    }
  }
}

export default withTranslation()(FriendsProfileComponent);
