import React, { Component } from "react";
import { connect } from "react-redux";
import ProfileImage from "../../../assets/images/svg/profile-pic-120.svg";
import { ValidURL } from "../../../utils/date";
import i18n from "../../../i18n";

class DashboardPopularHostCardComponent extends Component {
  addDefaultSrc(ev) {
    ev.target.src = ProfileImage;
  }

  prepareTagClass(brandName) {
    return "gtmViewBrand-" + this.props.order;
  }

  render() {
    const lang = i18n.language;
    const { allOnlineUserList } = this.props;
    return (
      <div className="card popular-host text-center popularBox">
        <figure className="mr-2 mr-md-4">
          <a
            href={"/" + lang + "/host/" + this.props.id}
            className={this.prepareTagClass(this.props.brandName)}
          >
            <img
              className="profile-pic-120"
              src={this.props.avatar ? this.props.avatar : ProfileImage}
              alt={this.props.brandName}
              onError={this.addDefaultSrc}
            />
          </a>
          {allOnlineUserList.includes(this.props.organizerId) ? (
            <figcaption className="online-120" />
          ) : null}
          <figcaption className="text-secondary mt-3 brand-label">
            <a
              href={"/" + lang + "/host/" + this.props.id}
              className={
                (process.env.REACT_APP_PLATFORM === "nickx"
                  ? "text-orange "
                  : "text-secondary ") + this.prepareTagClass(this.props.name)
              }
            >
              {this.props.name}
            </a>
          </figcaption>
        </figure>
      </div>
    );
  }
}

const defaultProps = {
  id: 1,
  name: "Dunkin Donuts",
  brandAvatar: ProfileImage
};

const mapStateToProps = state => {
  return {
    isLoggedIn: state.login.isLoggedIn,
    allOnlineUserList: state.socketusers.allOnlineUserList
  };
};

DashboardPopularHostCardComponent.defaultProps = defaultProps;

export default connect(
  mapStateToProps,
  defaultProps
)(DashboardPopularHostCardComponent);
