import React, { Component } from "react";
import { connect } from "react-redux";
import { refactorTitle, refactorContent } from "./../../../utils/misc";
import CardImage from "../../../assets/images/card-sm.jpg";
import { timeSince } from "../../../utils/date";
import i18n from "../../../i18n";
import { withTranslation } from "react-i18next";

class DashboardNewsCardComponent extends Component {
  addDefaultSrc(ev) {
    ev.target.src = CardImage;
  }

  render() {
    const lang = i18n.language;
    const { t } = this.props;
    let date = timeSince(this.props.createdOn);
    // date = date.replace("months", t("months").replace("days", t("days")));

    date = date
      .replace("months", t("months"))
      .replace("Ended", t("ended"))
      .replace("days", t("days"))
      .replace("Ends", t("ends"))
      .replace("hours", t("hours"))
      .replace("Starting in", t("starttingIn"))
      .replace("day", t("day"));

    return (
      <div className="card quick-play">
        <a href={"/" + lang + "/news/" + this.props.id} target="_blank">
          <div
            className={
              "card-header light" +
              (process.env.REACT_APP_PLATFORM === "nickx" ? " nickx" : "")
            }
          >
            {this.props.categoryName}
          </div>
          <img
            className="card-img"
            src={
              this.props.thumbImageUrl
                ? this.props.thumbImageUrl
                : this.props.imageUrl
            }
            alt={this.props.title}
            onError={this.addDefaultSrc}
          />
          <h4 className="mt-3 bold" title={this.props.title}>
            {" "}
            {refactorTitle(this.props.title, 15)}
          </h4>
        </a>
        {!this.props.displayContent ? (
          ""
        ) : (
          <p className="my-3">{refactorContent(this.props.content)}</p>
        )}
        <small>
          {date} {t("ago")}
        </small>
      </div>
    );
  }
}

const mapDispatchToProps = {
  id: "9",
  title: "Vodafone partnering with GamingBattleGround!",
  content: "",
  imageUrl: CardImage,
  createdOn: "2017-09-15 07:29:56+00",
  categoryName: "Tournament"
};

const mapStateToProps = state => {
  return {
    isLoggedIn: state.login.isLoggedIn
  };
};

DashboardNewsCardComponent.defaultProps = mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(DashboardNewsCardComponent));
