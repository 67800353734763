import React, { Component } from "react";
import "swiper/dist/css/swiper.css";
import "./ClaimComponent.scss";
import claimPrizeZiggles from "../../assets/images/claimPirze-ziggles.png";
import Select2Component from "../Select2/Select2Component";
import NotFoundContainer from "../../containers/NotFoundContainer";
import loadinggif from "../../assets/images/loading.gif";
import { APP_MESSAGES } from "../../services/config/messages";
import AppInputComponent from "../../layouts/components/sidebars/AppInputComponent";
import { validateEmail } from "../../utils/validators";
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody
} from "react-accessible-accordion";
import adv13 from "../../assets/images/adv/adv-13.png";
import adv14 from "../../assets/images/adv/adv-5.png";
import adv14Nicks from "../../assets/images/adv/adv-nick.png";
import banner1 from "../../assets/images/adv/banner-1.jpeg";
import { withTranslation, Trans } from "react-i18next";
class ClaimComponent extends Component {
  componentDidMount() {
    let notificationId = this.props.match.params.id;
    let challengeId = this.props.match.params.challengeId;
    this.props.verifyPrizeFormUpdateField("challengeId", challengeId);
    this.props.verifyPrizeFormUpdateField("notificationId", notificationId);
    this.props.verifyPrizeFormSubmit({
      notificationId: notificationId,
      challengeId: challengeId,
      profileId: this.props.userId
    });

    this.props.getLastPrizeFormSubmit({
      profileId: this.props.userId
    });
  }

  render() {
    if (this.props.verifyPrizeForm.isSubmitting) {
      return (
        <div className="text-center">
          <div className="col-sm-12">
            <img src={loadinggif} alt="" />
          </div>
        </div>
      );
    }
    const { t } = this.props;
    const verifyResponse = this.props.verifyPrizeForm.fields.verifyResponse
      .value;

    if (!verifyResponse) {
      return <NotFoundContainer />;
    }

    const { claimChallengeData } = this.props;

    const { organizerAvatarUrl, challengeImageUrl } = claimChallengeData.item;

    const selectBankOptions = [
      { id: 1, value: "State Bank Of India" },
      { id: 2, value: "Bank Of Baroda" },
      { id: 3, value: "Swiss Bank" },
      { id: 4, value: "HDFC Bank" }
    ];
    const selectContractProviderOptions = [
      { id: 1, value: "Contract Provider 1" },
      { id: 2, value: "Contract Provider 2" },
      { id: 3, value: "Contract Provider 3" },
      { id: 4, value: "Contract Provider 4" }
    ];
    const bgImgStyle = challengeImageUrl
      ? {
          backgroundImage: "url(" + challengeImageUrl + ")"
        }
      : {};

    let Error;
    let Success;

    Error = this.props.claimPrizeForm.fields.email.error
      ? APP_MESSAGES[this.props.claimPrizeForm.fields.email.errorMsg](
          this.props.claimPrizeForm.fields.email.errorPayload,
          () => {
            this.props.claimPrizeFormResetError("email");
          }
        )
      : props => <>{""}</>;

    Success = this.props.claimPrizeForm.fields.email.success
      ? APP_MESSAGES[this.props.claimPrizeForm.fields.email.successMsg](
          this.props.claimPrizeForm.fields.email.successPayload,
          () => {
            this.props.claimPrizeFormResetSuccess("email");
          }
        )
      : props => <>{""}</>;
    let verifyPoints = verifyResponse.points;
    let itemdescription = verifyResponse.itemdescription;
    return (
      <>
        <section className="container-fluid banner-wrapper">
          <div className="row no-gutters no-space">
            <div className="col-sm-12">
              <div className="banner d-flex justify-content-center align-items-center">
                <img src={banner1} alt="" className="dynamic-banner" />

                {/* <div>
                <div className="banner-advertising lg d-none d-lg-block"> */}
                {/* <img src={adv13} alt="" className="d-none d-lg-block" /> */}
                {/* </div>
              </div> */}
              </div>
              <div className="clam-prize-wrapper w-100">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-12  text-center">
                      <h1 className="py-1 py-sm-4">{t("claimPrize.title")}</h1>
                    </div>
                  </div>
                </div>
                <img
                  src={claimPrizeZiggles}
                  className="clam-prize-zaggles"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
        <section className="[ container-fluid ] receive-bitcoin section-border-top">
          <div className="padder-main">
            <div className="row">
              <div className="container">
                <div className="row d-flex align-items-center justify-content-center">
                  <div className="col-lg-12 col-xl-9 text-center">
                    {verifyResponse.itemurl ? (
                      <div className="receive-bitcoin-img">
                        <img
                          src={verifyResponse.itemurl}
                          className="w-100"
                          alt=""
                        />
                      </div>
                    ) : null}
                    <h2 className="bold mt-4 mt-sm-5">
                      {itemdescription && verifyPoints ? (
                        <Trans
                          i18nKey="youWillReceive"
                          itemdescription={itemdescription}
                          verifyPoints={verifyPoints}
                        >
                          You will Receive {{ itemdescription }} +{" "}
                          {{ verifyPoints }} tokens
                        </Trans>
                      ) : !verifyPoints ? (
                        <Trans
                          i18nKey="receiveItem"
                          itemdescription={itemdescription}
                        >
                          {" "}
                          You will Receive {{ itemdescription }}
                        </Trans>
                      ) : (
                        <Trans
                          i18nKey="receiveToken"
                          verifyPoints={verifyPoints}
                        >
                          You will Receive {{ verifyPoints }} tokens
                        </Trans>
                      )}
                    </h2>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 mt-6 d-flex justify-content-center align-items-start">
                    {/* <img
                      src={
                        process.env.REACT_APP_PLATFORM === "nickx"
                          ? adv14Nicks
                          : adv14
                      }
                      className="banner-advertising web-square"
                      alt=""
                    /> */}
                  </div>
                </div>
                <div className="row prize-form-wrapper d-flex align-items-center justify-content-center">
                  <div className="col-lg-12 col-xl-9 text-center">
                    <h1 className="bold">{t("claimPrize.title")} </h1>
                    <h5 className="mt-4">
                      {t("claimPirze.readyToGetDesc")}{" "}
                      {process.env.REACT_APP_PLATFORM === "nickx" ? (
                        <a
                          href="mailto:info@nickx.co"
                          className="text-secondary"
                        >
                          info@nickx.co
                        </a>
                      ) : (
                        <a
                          href="mailto:info@arcadex.co"
                          className="text-secondary"
                        >
                          info@arcadex.co
                        </a>
                      )}
                    </h5>
                  </div>

                  {verifyResponse.itemdescription !== null &&
                  verifyResponse.itemurl !== null ? (
                    <div className="col-lg-12 col-xl-9 ">
                      <Error />
                      <Success />
                      <form
                        className="get-prize-form"
                        onSubmit={e => {
                          e.preventDefault();
                          if (!this.props.claimPrizeForm.isSubmitting) {
                            if (!this.props.claimPrizeForm.fields.email.value) {
                              this.props.claimPrizeFormSetError(
                                "email",
                                "NOT_EMPTY"
                              );
                              return false;
                            }

                            if (
                              !validateEmail(
                                this.props.claimPrizeForm.fields.email.value
                              )
                            ) {
                              this.props.claimPrizeFormSetError(
                                "email",
                                "EMAIL"
                              );
                              return false;
                            }

                            this.props.claimPrizeFormSubmit({
                              notificationId: this.props.match.params.id,
                              challengeId: 100,
                              profileId: this.props.userId,
                              firstName: this.props.claimPrizeForm.fields
                                .firstName.value,
                              lastName: this.props.claimPrizeForm.fields.sirName
                                .value,
                              cellNumber: this.props.claimPrizeForm.fields
                                .contactNumber.value,
                              contactProvider: this.props.claimPrizeForm.fields
                                .contractProvider.value,
                              emailAddress: this.props.claimPrizeForm.fields
                                .email.value,
                              residence: this.props.claimPrizeForm.fields
                                .residence.value
                            });
                            return false;
                          }
                        }}
                      >
                        <h4 className="bold mt-6">
                          {t("claimPirze.personalDetails")}
                        </h4>
                        <div className="form-group with-lable ">
                          <div className="row">
                            <div className="col-sm-6 ">
                              <AppInputComponent
                                value={
                                  this.props.claimPrizeForm.fields.firstName
                                    .value
                                }
                                labelText={t('firstName')}
                                labelClass=""
                                onChange={event => {
                                  this.props.claimPrizeFormUpdateField(
                                    "firstName",
                                    event.target.value
                                  );
                                }}
                              />
                            </div>
                            <div className="col-sm-6 ">
                              <AppInputComponent
                                value={
                                  this.props.claimPrizeForm.fields.sirName.value
                                }
                                labelText={t('lastName')}
                                labelClass=""
                                onChange={event => {
                                  this.props.claimPrizeFormUpdateField(
                                    "sirName",
                                    event.target.value
                                  );
                                }}
                              />
                            </div>
                            <div className="col-sm-6 ">
                              <AppInputComponent
                                value={
                                  this.props.claimPrizeForm.fields.contactNumber
                                    .value
                                }
                                labelText={t('contactNumber')}
                                labelClass=""
                                onChange={event => {
                                  this.props.claimPrizeFormUpdateField(
                                    "contactNumber",
                                    event.target.value
                                  );
                                }}
                              />
                            </div>

                            <div className="col-sm-6">
                              <div className="input-group-wrapper">
                                <div className="dropdown w-100">
                                  <Select2Component
                                    selectOptions={
                                      selectContractProviderOptions
                                    }
                                    onChange={event => {
                                      this.props.claimPrizeFormUpdateField(
                                        "contractProvider",
                                        event
                                      );
                                    }}
                                    selectedValue={
                                      this.props.claimPrizeForm.fields
                                        .contractProvider.value
                                    }
                                    labelText={t('contractProvider')}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6 ">
                              <AppInputComponent
                                value={
                                  this.props.claimPrizeForm.fields.email.value
                                }
                                type="email"
                                labelText={t('labelEmailAdd')}
                                labelClass=""
                                onChange={event => {
                                  this.props.claimPrizeFormUpdateField(
                                    "email",
                                    event.target.value
                                  );
                                }}
                              />
                            </div>

                            <div className="col-sm-6 ">
                              <AppInputComponent
                                labelText={t('lblResidence')}
                                labelClass=""
                                value={
                                  this.props.claimPrizeForm.fields.residence
                                    .value
                                }
                                onChange={event => {
                                  this.props.claimPrizeFormUpdateField(
                                    "residence",
                                    event.target.value
                                  );
                                }}
                              />
                            </div>
                          </div>
                          {/* <h4 className="bold mt-6">Banking Details</h4>
                        <p className="fs-12 semi-bold mt-2">
                          Only fill this in if you’ve won a cash prize.
                        </p>
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="input-group-wrapper">
                              <div className="dropdown w-100">
                                <Select2Component
                                  selectOptions={selectBankOptions}
                                  onChange={event => {
                                    this.props.claimPrizeFormUpdateField(
                                      "bankName",
                                      event
                                    );
                                  }}
                                  selectedValue={
                                    this.props.claimPrizeForm.fields.bankName
                                      .value
                                  }
                                  labelText={"Bank Name"}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 ">
                            <AppInputComponent
                              value={
                                this.props.claimPrizeForm.fields.branchCode
                                  .value
                              }
                              labelText="Branch Code"
                              labelClass=""
                              onChange={event => {
                                this.props.claimPrizeFormUpdateField(
                                  "branchCode",
                                  event.target.value
                                );
                              }}
                            />
                          </div>

                          <div className="col-sm-12 ">
                            <AppInputComponent
                              value={
                                this.props.claimPrizeForm.fields.accHolderName
                                  .value
                              }
                              labelText="Account Holder Name"
                              labelClass=""
                              onChange={event => {
                                this.props.claimPrizeFormUpdateField(
                                  "accHolderName",
                                  event.target.value
                                );
                              }}
                            />
                          </div>

                          <div className="col-sm-6 ">
                            <AppInputComponent
                              value={
                                this.props.claimPrizeForm.fields.accNumber.value
                              }
                              labelText="Account Number"
                              labelClass=""
                              onChange={event => {
                                this.props.claimPrizeFormUpdateField(
                                  "accNumber",
                                  event.target.value
                                );
                              }}
                            />
                          </div>

                          <div className="col-sm-6 ">
                            <AppInputComponent
                              value={
                                this.props.claimPrizeForm.fields.accType.value
                              }
                              labelText="Account Type"
                              labelClass=""
                              onChange={event => {
                                this.props.claimPrizeFormUpdateField(
                                  "accType",
                                  event.target.value
                                );
                              }}
                            />
                          </div>
                        </div>
                       
                        */}

                          <div className="row mt-2 mt-sm-6 button-wrapper">
                            <div className="col-12 col-sm">
                              <a
                                href="/"
                                className="btn btn-white cancel dark btn-block"
                              >
                                {t("claimPirze.cancel")}
                              </a>
                            </div>
                            <div className="col-12 col-sm">
                              <button
                                type="submit"
                                name="Send"
                                className="btn btn-pink light btn-block"
                              >
                                {t("claimPirze.send")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="[ container-fluid ] upcoming-tournament section-border-top">
          <div className="padder-main">
            <div className="row">
              <div className="container">
                <div className="row d-flex justify-content-center align-items-center">
                  <div className="col-xl-9">
                    <div className=" clam-prize-accordian w-100">
                      <div className="tournament-accordian mt-3">
                        <Accordion>
                          <AccordionItem className="card bb-1">
                            <AccordionItemTitle className="card-header">
                              <h4 className="semi-bold text-left u-position-relative">
                                {t("claimPirze.about")}
                                <div
                                  className="accordion__arrow"
                                  role="presentation"
                                />
                              </h4>
                            </AccordionItemTitle>
                            <AccordionItemBody>
                              <div className="card-body text-left">
                                {t("claimPirze.description1")}
                              </div>
                            </AccordionItemBody>
                          </AccordionItem>

                          <AccordionItem className="card bb-1">
                            <AccordionItemTitle className="card-header">
                              <h4 className="semi-bold text-left u-position-relative">
                                {t("claimPirze.rules")}
                                <div
                                  className="accordion__arrow"
                                  role="presentation"
                                />
                              </h4>
                            </AccordionItemTitle>
                            <AccordionItemBody>
                              <div className="card-body text-left">
                                {t("claimPirze.description2")}
                              </div>
                            </AccordionItemBody>
                          </AccordionItem>
                          <AccordionItem className="card bb-1">
                            <AccordionItemTitle className="card-header">
                              <h4 className="semi-bold text-left u-position-relative">
                                {t("claimPirze.prizes")}
                                <div
                                  className="accordion__arrow"
                                  role="presentation"
                                />
                              </h4>
                            </AccordionItemTitle>
                            <AccordionItemBody>
                              <div className="card-body text-left">
                                {t("claimPirze.description3")}
                              </div>
                            </AccordionItemBody>
                          </AccordionItem>
                          <AccordionItem className="card bb-1">
                            <AccordionItemTitle className="card-header">
                              <h4 className="semi-bold text-left u-position-relative">
                                {t("claimPirze.host")}
                                <div
                                  className="accordion__arrow"
                                  role="presentation"
                                />
                              </h4>
                            </AccordionItemTitle>
                            <AccordionItemBody>
                              <div className="card-body text-left">
                                {t("claimPirze.description4")}
                              </div>
                            </AccordionItemBody>
                          </AccordionItem>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
export default withTranslation()(ClaimComponent);
