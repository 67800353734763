import React, { Component } from "react";
import { connect } from "react-redux";
import { questionForm } from "../../actions/forms";
import AppInputComponent from "../../layouts/components/sidebars/AppInputComponent";
import AppTextAreaComponent from "../../layouts/components/sidebars/AppTextAreaComponent";
import { validateEmail } from "../../utils/validators";
import { APP_MESSAGES } from "../../services/config/messages";

class HaveAnotherQuestionComponent extends Component {
  componentDidMount() {}

  //validation rules
  handleValidation() {
    let fields = this.props.questionForm.fields;

    //player name
    if (!fields.playerName.value) {
      this.props.questionFormSetError("email", "NOT_EMPTY");
      return false;
    }

    //email
    if (!fields.email.value) {
      this.props.questionFormSetError("email", "NOT_EMPTY");
      return false;
    }

    if (!validateEmail(fields.email.value)) {
      this.props.questionFormSetError("email", "EMAIL");
      return false;
    }

    //question
    if (!fields.question.value) {
      this.props.questionFormSetError("email", "NOT_EMPTY");
      return false;
    }

    return true;
  }

  //submit form
  submitQuestionForm(e) {
    e.preventDefault();
    let postParams;

    if (this.handleValidation()) {
      //console.log("validated");
      postParams = {
        playerName: this.props.questionForm.fields.playerName.value,
        email: this.props.questionForm.fields.email.value,
        question: this.props.questionForm.fields.question.value
      };
      this.props.questionFormSubmit(postParams);
    } else {
      //console.log("not validated");
    }
  }

  getSuccessMsg() {
    let fields = this.props.questionForm.fields;

    if (fields.email.successMsg) {
      return APP_MESSAGES[fields.email.successMsg](fields.email.successPayload);
    } else {
      return props => <>{""}</>;
    }
  }

  getErrorMsg() {
    let fields = this.props.questionForm.fields;

    if (fields.email.errorMsg) {
      return APP_MESSAGES[fields.email.errorMsg](
        fields.email.errorPayload,
        () => {
          this.props.questionFormResetError("email");
        }
      );
    } else {
      return props => <>{""}</>;
    }
  }

  render() {
    let ErrorMsg;
    let SuccessMsg;

    const { t } = this.props;
    ErrorMsg = this.getErrorMsg();
    SuccessMsg = this.getSuccessMsg();
    let sectionClassName =
      "[ container-fluid ] bg-light-gradient  question-wrapper section-border-top";
    let formNamelabelText = t("playerName")
    if (this.props.type) {
      formNamelabelText = "Name";
      sectionClassName =
        "[ container-fluid ] bg-light-gradient  question-wrapper contactPageForm";
    }
    return (
      <>
        <section className={sectionClassName}>
          <div className="padder-main">
            <div className="row">
              <div className="container">
                <div className="row question-form justify-content-center">
                  <div className="col-sm-12 col-xl-9 d-flex align-items-center justify-content-start">
                    <form
                      className="get-prize-form w-100"
                      id="question-form"
                      onSubmit={this.submitQuestionForm.bind(this)}
                    >
                      <div className="form-group with-lable ">
                        <h2 className="bold">
                          {!this.props.type
                            ? t("helpSupportPage.haveAnotherQue")
                            : "Get in Touch Today!"}
                        </h2>
                        {!this.props.type ? (
                          <h5 className="mt-2">
                           {t("helpSupportPage.fillInForm")}
                          </h5>
                        ) : null}
                        <ErrorMsg />
                        <SuccessMsg />
                        <div className="row">
                          <div className="col-sm-6 ">
                            <AppInputComponent
                              divClass="form-group with-lable mt-5"
                              labelText={formNamelabelText}
                              onChange={event => {
                                this.props.questionFormUpdateField(
                                  "playerName",
                                  event.target.value
                                );
                                this.props.questionFormResetError("email");
                              }}
                              value={
                                this.props.questionForm.fields.playerName.value
                              }
                            />
                          </div>
                          <div className="col-sm-6 ">
                            <AppInputComponent
                              divClass="form-group with-lable mt-5"
                              labelText="Email"
                              onChange={event => {
                                this.props.questionFormUpdateField(
                                  "email",
                                  event.target.value
                                );
                                this.props.questionFormResetError("email");
                              }}
                              value={this.props.questionForm.fields.email.value}
                            />
                          </div>

                          {!this.props.type ? (
                            <div className="col-sm-12 ">
                              <AppInputComponent
                                divClass="form-group with-lable mt-5"
                                labelText={t("helpSupportPage.whaiIsQue")}
                                onChange={event => {
                                  this.props.questionFormUpdateField(
                                    "question",
                                    event.target.value
                                  );
                                  this.props.questionFormResetError("email");
                                }}
                                value={
                                  this.props.questionForm.fields.question.value
                                }
                              />
                            </div>
                          ) : (
                            <div className="col-sm-12 ">
                              <AppTextAreaComponent
                                divClass="form-group with-lable mt-5"
                                labelText="Message"
                                onChange={event => {
                                  this.props.questionFormUpdateField(
                                    "question",
                                    event.target.value
                                  );
                                  this.props.questionFormResetError("email");
                                }}
                                value={
                                  this.props.questionForm.fields.question.value
                                }
                              />
                            </div>
                          )}
                        </div>

                        <div className="row mt-2 mt-sm-6 button-wrapper">
                          <div className="col-12 col-sm text-center">
                            <button
                              type="submit"
                              className={
                                "btn btn-pink dark btn-lg" +
                                (this.props.questionForm.isSubmitting
                                  ? " disable"
                                  : "")
                              }
                            >
                              {t("send")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapDispatchToProps = {
  questionFormSubmit: questionForm.submit,
  questionFormReset: questionForm.reset,
  questionFormResetField: questionForm.resetField,
  questionFormUpdateField: questionForm.updateField,
  questionFormSetError: questionForm.setError,
  questionFormSetSuccess: questionForm.setSuccess,
  questionFormResetError: questionForm.resetError,
  questionFormResetSuccess: questionForm.resetSuccess
};

const mapStateToProps = state => {
  return {
    questionForm: state.forms.questionForm
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HaveAnotherQuestionComponent);
