import React, { Component } from "react";
import RaffleCardComponent from "../Raffles/RaffleCardComponent";
import loadinggif from "../../assets/images/loading.gif";
import navArrowRightBlue from "../../assets/images/svg/nav-arrow-right-blue.svg";
import TokenImage from "../../assets/images/token.png";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";

class RafflesViewAllComponent extends Component {
  componentDidMount() {
    const {
      fetchRafflesViewAllIfNeeded,
      invalidateAllRafflesViewAll
    } = this.props;
    invalidateAllRafflesViewAll();
    fetchRafflesViewAllIfNeeded({ page: 1 });
  }

  componentDidUpdate() {
    document.body.classList.remove("modal-open");
  }

  render() {
    let { page, tokensInWallet, t } = this.props;
    const lang = i18n.language
    return (
      <>
        <section className="[ container-fluid ] enter-raffles-wrapper">
          <div className="row justify-content-center">
            <div
              className={
                "col-xl-3 col-10  bg-wallet" +
                (process.env.REACT_APP_PLATFORM === "nickx" ? " nickx" : "")
              }
            >
              <div className="row no-gutters d-flex justify-content-center align-items-center">
                <div className="col-auto">
                  <h4 className="text-white semi-bold">
                    {" "}
                    <span className="bold">
                      {tokensInWallet ? tokensInWallet : 0}
                    </span>{" "}
                    {t("tokensInWallet")}
                  </h4>
                </div>
                <div className="col-auto">
                  <img src={TokenImage} className="ml-2" alt="" />
                </div>
              </div>
            </div>
          </div>
          {/* </section>
        <section className="container-fluid my-tournament-wrapper"> */}
          <div className="row">
            <div className="container">
              <div className="padder-main">
                <div className="row d-flex align-items-center mb-30 ">
                  <div className="col-sm-12 text-right d-flex justify-content-start mb-2 back-arrow">
                    <a
                      href={"/" + lang + "/raffles"}
                      className="view-all d-flex align-items-center"
                    >
                      <span>
                        <img src={navArrowRightBlue} alt="" />
                      </span>
                      <h5 className="bold"> {t("back")} </h5>{" "}
                    </a>
                  </div>
                  <div className="col-sm-12">
                    <h2 className="bold">{t("enterRaffle")}</h2>
                    <h5 className="mt-4">{t("useYourToken")} </h5>
                  </div>
                </div>

                <div className="row card-wrapper d-flex justify-content-center justify-content-sm-start">
                  {this.props.rafflesViewAll.items.length > 0
                    ? this.props.rafflesViewAll.items.map((tournament, i) => (
                        <div className="col-auto mb-4" key={i}>
                          <RaffleCardComponent
                            {...tournament}
                            index={i + 1}
                            displayEnteredCount={false}
                            cardClass="card card-my-tournament"
                            brandAvatarClass="profile-pic-56"
                          />
                        </div>
                      ))
                    : ""}
                </div>
                <div className="row mb-30 text-center">
                  <div className="col-sm-12">
                    {this.props.rafflesViewAll.items.length > 0 ? (
                      !this.props.rafflesViewAll.isLastPage ? (
                        <button
                          onClick={() => {
                            this.props.invalidateRafflesViewAll();
                            this.props.fetchRafflesViewAllIfNeeded({
                              page: page
                            });
                          }}
                          className="btn btn-pink dark btn-xs"
                        >
                          {t("loadMore")}
                        </button>
                      ) : this.props.rafflesViewAll.isFetching ? (
                        <img src={loadinggif} alt="" />
                      ) : (
                        ""
                      )
                    ) : this.props.rafflesViewAll.isFetching ? (
                      <img className="text-center" src={loadinggif} alt="" />
                    ) : (
                      <span>{t("noRaffleFound")}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default withTranslation()(RafflesViewAllComponent);
