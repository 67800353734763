import React, { Component } from "react";
import { connect } from "react-redux";
import { leftSidebar } from "../../../../actions/sidebars";
import Logo from "../../../../assets/images/svg/logo.svg";
import LogoNickx from "../../../../assets/images/logo-nick.png";
import CloseIcon from "../../../../assets/images/svg/nav-close.svg";
import { withRouter } from "react-router";
import i18n from "../../../../i18n";
import { isNgCountry } from "../../../../utils/misc";

class LeftSidebarWithoutLogin extends Component {
  constructor(props) {
    super(props);
    this.showLeftSidebar = this.showLeftSidebar.bind(this);
    this.hideLeftSidebar = this.hideLeftSidebar.bind(this);
    this.hideLeftSidebarProxy = this.hideLeftSidebarProxy.bind(this);
    this.state = {
        isActiveNestingMenu: false
    }
  }

  componentWillMount() {
    document.addEventListener("mousedown", this.hideLeftSidebarProxy, false);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.hideLeftSidebarProxy, false);
  }
  showLeftSidebar() {
    this.setState({isActiveNestingMenu: false});
    this.props.leftSidebarOpen();
  }
  hideLeftSidebar() {
      this.props.leftSidebarClose();
      this.setState({isActiveNestingMenu: false});
  }
  hideLeftSidebarProxy = e => {
    if (this.leftSidebar && this.leftSidebar.contains(e.target)) {
      return;
    }
    if (this.props.isLeftSidebarOpen) {
      this.hideLeftSidebar();
    }
    return;
  };

  render() {
    const  {isActiveNestingMenu } = this.state;
    let envClass = process.env.REACT_APP_PLATFORM === "nickx" ? " nickx" : "";
    const lang = i18n.language;
    return (
      <>
        <nav
          className={
            "d-block d-xl-none left-sidebar" +
            (this.props.isLeftSidebarOpen ? " active" : "")
          }
          ref={leftSidebar => {
            this.leftSidebar = leftSidebar;
          }}
        >
          <div className="dismiss" onClick={this.hideLeftSidebar}>
            <span className="icon-close">
              <img src={CloseIcon} alt="" />
            </span>
          </div>
          <div className="sidebar-header">
            <div className="navbar-brand">
              <a href={"/" + lang}>
                <img
                  src={
                    process.env.REACT_APP_PLATFORM === "nickx"
                      ? LogoNickx
                      : Logo
                  }
                  alt=""
                  className="sidebarLogo"
                />
              </a>
            </div>
          </div>
          <ul className="list-unstyled mt-4">
            <li className="nav-item">
              <span className="nav-link nestingMenu" onClick={() => {
                  this.setState((state, props) => ({
                      isActiveNestingMenu: !state.isActiveNestingMenu
                  }))
              }} >
                Support 
              </span>
            </li>
            <div className={"subMenuContainer " + (isActiveNestingMenu ? 'fadein' : 'fadeout')}>
                <li className={"nav-item sub-item "}>
                    <a
                    href={"/" + lang + "/help-support"}
                    className={"nav-link " + envClass}
                    >
                        Help & Support
                    </a>
                </li>
                <li className={"nav-item sub-item "}>
                    <a
                    href={"/" + lang + "/terms-conditions"}
                    className={"nav-link " + envClass}
                    >
                        Terms & Conditions
                    </a>
                </li>
                <li className={"nav-item sub-item "}>
                    <a
                    href={"/" + lang + "/privacy-policy"}
                    className={"nav-link " + envClass}
                    >
                        Privacy Policy
                    </a>
                </li>
                {/* <li className={"nav-item sub-item "}>
                    <a
                    href={"/" + lang + "/contact-us"}
                    className={"nav-link " + envClass}
                    >
                        Unsubscribe
                    </a>
                </li> */}
            </div>
            { 
              !isNgCountry() ? 
                <li className="nav-item">
                  <a
                    href={"/" + lang + "/#raffles"}
                    className={"nav-link " + envClass}
                  >
                    Raffles
                  </a>
                </li>
             : <></>
            }
            <li className="nav-item">
              <a
                href={"/" + lang + "/#tournaments"}
                className={"nav-link " + envClass}
              >
                Tournaments
              </a>
            </li>
            <li className="nav-item">
              <a href={"/" + lang + "/news"} className={"nav-link " + envClass}>
                News
              </a>
            </li>
            <li className="nav-item">
              <a
                href={"/" + lang + "/contact-us"}
                className={"nav-link " + envClass}
              >
                Contact Us
              </a>
            </li>
          </ul>
        </nav>
      </>
    );
  }
}
const mapDispatchToProps = {
  leftSidebarOpen: leftSidebar.open,
  leftSidebarClose: leftSidebar.close
};

const mapStateToProps = state => {
  return {
    isLeftSidebarOpen: state.sidebars.isLeftSidebarOpen
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LeftSidebarWithoutLogin)
);
