import React, { Component } from "react";
import banner1 from "../../../assets/images/adv/banner-1.jpeg";
import telokomBanner1 from "../../../assets/images/adv/telkom-banner-1.png";
import "./DashboardBannerComponent.scss";
import i18n from "../../../i18n";
import { isNgCountry } from "../../../utils/misc";

class SlideBannerComponent extends Component {
  prepareTagClass(brandName) {
    return " gtmHomeBanner-HB" + this.props.order;
  }

  render() {
    const { history } = this.props;
    const { id, promoImage, name, brandName, brandId, order } = this.props.challengeData;
    const lang = i18n.language;
    return (
      <>
        <div className="">
          <div className="banner dashboard d-flex flex-column align-items-center">
            <img
              src={
                promoImage
                  ? promoImage
                  : process.env.REACT_APP_ENV === "DEV"
                    ? telokomBanner1
                    : banner1
              }
              alt=""
              className="dashboard-dynamic-banner"
            />
            <div
              className={
                "book-yoour-seat w-100 mt-auto" +
                (process.env.REACT_APP_PLATFORM === "nickx" ? " nickx" : "")
              }
            >
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-sm-12 col-lg-7">
                    <div className="row">
                      <div className="col d-flex align-items-center justify-content-center">
                        <h4 className="text-white">{name}</h4>
                      </div>
                      <div className="col d-flex align-items-center justify-content-center">
                        <a
                          href="javascript:void(0)"
                          onClick={() => {
                            window.dataLayer &&  window.dataLayer.push({
                              'event': "gtmHomeBanner-HB" + id,
                              'Click Classes': "HB" + id,
                              'challengeId': id,
                              'challengeTitle': name,
                              'brandId': brandId,
                              'brandName': brandName,
                              'eventCallback': function () {
                                history.push("/" + lang + "/challenge/" + id);
                              }
                            });
                            isNgCountry() && history.push("/" + lang + "/challenge/" + id);
                          }}
                          // href={"/" + lang + "/challenge/" + id}
                          className={
                            "btn btn-pink btn-block dark" +
                            this.prepareTagClass(brandName)
                          }
                        >
                          {this.props.t("bookYourSeet")}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default SlideBannerComponent;
